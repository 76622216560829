import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const Addtrainers = () => {
  const [formData, setFormData] = useState({
    trainername: "",
    trainercourse: "",
    description: "",
    email: "",
    phonenumber: "",
    address: "",
    teaching:"",
    communication:"",
    support:"",
    trainerimage: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${ROOT_URL}/trainerspost`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.message);

        setFormData({
          trainername: "",
          trainercourse: "",
          description: "",
          email: "",
          phonenumber: "",
          address: "",
          teaching:"",
          communication:"",
          support:"",
          trainerimage: null,
        });
        // Display toast message for 5 seconds
        toast.success(response.data.message, { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error adding of trainer  :", error);
        // Display toast message for login failure
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
  };

  const [trainerimage, setImage] = useState(null);
  const [imageName, setImageName] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, trainerimage: file }); // Set the file in form data

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
      setImageName(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImageName("");
    setFormData({ ...formData, trainerimage: null });
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Add Trainers</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="trainerNameInput">Trainer Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Trainer Name"
            id="trainerName"
            name="trainername"
            value={formData.trainername}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="trainerCourseInput">Trainer Course</label>
          <input
            type="text"
            className="form-control"
            placeholder="Trainer Course"
            id="trainerCourse"
            name="trainercourse"
            value={formData.trainercourse}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="descriptionInput">Description</label>
          <input
            type="text"
            className="form-control"
            placeholder="Description"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="emailInput">Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="phoneNumberInput">Phone Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="Phone Number"
            id="phoneNumber"
            name="phonenumber"
            value={formData.phonenumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="addressInput">Address</label>
          <input
            type="text"
            className="form-control"
            placeholder="Address"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="teachingInput">Teaching</label>
          <input
            type="text"
            className="form-control"
            placeholder="Teaching"
            id="teaching"
            name="teaching"
            value={formData.teaching}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="addressInput">Communication</label>
          <input
            type="text"
            className="form-control"
            placeholder="Communication"
            id="communication"
            name="communication"
            value={formData.communication}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="addressInput">Support</label>
          <input
            type="text"
            className="form-control"
            placeholder="Support"
            id="support"
            name="support"
            value={formData.support}
            onChange={handleChange}
          />
        </div>

        <div
          className="border border-gray rounded p-4 mb-3"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
            <h6 className="text-primary mb-3">Add Trainer Image</h6>
            <div className="input-group mb-3">
              <label htmlFor="inputGroupFile02" className="form-control">
                Choose File
                <input
                  type="file"
                  className="d-none"
                  id="inputGroupFile02"
                  name="trainerimage"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {trainerimage && (
            <div
              className="mt-3"
              style={{
                position: "relative",
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={trainerimage}
                  alt="Uploaded"
                  style={{ maxWidth: "20%" }}
                />
                <button
                  onClick={handleCancelImage}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              {imageName && (
                <div style={{ marginLeft: "10px" }}>
                  <p>{imageName}</p>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Addtrainers;
