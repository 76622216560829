import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const Updateslider = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { id } = useParams();
  const [formData, setFormData] = useState({
    maintitle: "",
    subtitle: "",
    sliderimage: null,
    imagename: null,
  });

  useEffect(() => {
 

    fetchcourses();
  }, [id]);

  const fetchcourses = async () => {
    try {
      const response = await axios.get(
        `${ROOT_URL}/sliderbyid`,
        { headers: { id: id } }
      );
      console.log("courses data:", response.data);
      const coursesData = response.data;

      // Populate formData with fetched data
      setFormData(prevState => ({
          ...prevState,
          maintitle: coursesData.data.maintitle,
        sliderimage: coursesData.data.sliderimage,
        subtitle: coursesData.data.subtitle,
        imagename: coursesData.data.imagename,
      }));
      setImageUrl(coursesData.data.sliderimage);
      console.log(formData)
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };




  ///////updatebyId courses/////


  const updateCourse = async () => {
    try {
      const formDataForUpdate = new FormData();
      formDataForUpdate.append("maintitle", formData.maintitle);
      formDataForUpdate.append("subtitle", formData.subtitle);
      formDataForUpdate.append("sliderimage", image);

      await axios.put(
        `${ROOT_URL}/idbyupdateslider`,
        formDataForUpdate,
        {
          headers: {
            id: id,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Course updated successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error("Error updating course:", error);
      toast.error("Failed to update course. Please try again later.");
    }
  };

  





  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
  
    if (selectedImage) {
      setImage(selectedImage);
  
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };
  

  const handleCancelImage = () => {
    setImage(null);
    setImageUrl("");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Update Courses</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Main Title</label>
          <input
            type="text"
            className="form-control"
            placeholder="Main Title"
            id="maintitle"
            name="maintitle"
            value={formData.maintitle}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Sub Title</label>
          <input
            type="text"
            className="form-control"
            placeholder="Sub Title"
            id="subtitle"
            name="subtitle"
            value={formData.subtitle}
            onChange={handleChange}
          />
        </div>
        <div
          className="border border-gray rounded p-4 mb-3"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
            <h6 className="text-primary mb-3">Add Image</h6>
            <div className="input-group mb-3">
              <label htmlFor="inputGroupFile02" className="form-control">
                Choose File
                <input
                  type="file"
                  className="d-none"
                  id="inputGroupFile02"
                  name="sliderimage"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {imageUrl  && (
            <div
              className="mt-3"
              style={{
                position: "relative",
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Uploaded"
                  style={{ maxWidth: "20%" }}
                />
                <button
                  onClick={handleCancelImage}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button type="button" className="btn btn-primary mt-2"  onClick={updateCourse}>
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Updateslider;

