import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ROOT_URL from "../../Utilities/apiconfig";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    // Fetch data from backend
    axios.get(`${ROOT_URL}/getgallery`)
    
      .then(response => {
        // Assuming the response data is an array of objects with a field 'galleryimage'
        setGalleryData(response.data);
      })
      .catch(error => {
        console.error('Error fetching gallery data:', error);
      });
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className="gallery-wrap">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="gallery_box">
              <div className="gallery_left">
                <div className="title">
                  <h1>Photo Gallery</h1>
                </div>
                <p>Join us as we embark on a transformative learning experience together!</p>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row">
              {galleryData.slice(0, 6).map((item, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="galleryImg">
                    <img src={item.galleryimage} alt="" style={{ objectFit: "cover" }} />
                    <div className="portfolio-overley">
                      <div className="content">
                        <a href={item.galleryimage} className="fancybox image-link" data-fancybox="images" title="Image Caption Here">
                          <i className="fas fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
