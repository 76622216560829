import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { QRCode } from "react-qrcode-logo";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Blog/Header/Header";
import Footer from "../Blog/Footer/Footer";
import ROOT_URL from "../Utilities/apiconfig";

export default function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const fees = queryParams.get("fees");
  const courseId = queryParams.get("courseId");
  const [referenceNumber, setReferenceNumber] = useState("");
  const user = useSelector((state) => state.auth.user);
  const userId = user ? user.id : null;


  const handleSubmit = async () => {
    try {
      // Check if reference number is provided
      if (!referenceNumber) {
        toast.error("Please enter the Payment reference number.");
        return;
      }

      // Send a POST request to the backend API to store the booking
      const response = await axios.post(
        `${ROOT_URL}/bookingspost`,
        {
          amount: fees,
          referenceNo: referenceNumber
        },
        {
          headers: {
            userid: userId,
            courseid: courseId
          }
        }
      );

      // Handle success response
      if (response.data && !response.data.isError) {
        toast.success("Booking created successfully.");
        // Redirect the user to a success page or any other page
       navigate("/userbookings");
      } else {
        toast.error("Failed to create booking.");
      }
    } catch (error) {
      console.error("Error creating booking:", error);
      toast.error("Failed to create booking. Please try again later.");
    }
  };
  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Payment Details</h1>
        </div>
      </div>
      <div className="content-container">
        <div className="qr-container">
          <label className="lable">ENTER THE PAYMENT REFERENCE NUMBER</label>
          <input
            type="text"
            className="input"
            value={referenceNumber}
            onChange={(e) => setReferenceNumber(e.target.value)}
            placeholder="Enter the Reference Number"
          />
          <br />
          <QRCode
            value={`upi://pay?pa=6309248410@ybl&pn=Brilliant-Academy&tn=Brilliant-Academy&am=${fees}`}
            size="300"
            logoImage="https://i.postimg.cc/5tdHfkxF/118852864-1241633666213183-4722008391193475906-n.png"
            logoWidth="80"
            logoHeight="100"
            logoOpacity="0.6"
          />
          <p>Scan the code using PhonePe, Google Pay or Paytm</p>
        </div>
        <div className="col-lg-3 button">
          <div className="header_info">
            <div className="loginwrp ">
              <a className="button1"  onClick={handleSubmit}>SUBMIT</a>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
}
