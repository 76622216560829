import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/authactions";
import Logo from "../../Dashboard/Images/logo.png";

const Header = () => {
  const user = useSelector((state) => state.auth.user);
  const userRole = user ? user.role : null;
  const dispatch = useDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logout());
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;

  return (
    <div className="header-wrap">
      <div className="container-fluid">
        <div className="row">
          {windowWidth > 768 && ( // Render address section only if window width is greater than 768px (tablet view)
            <div
              className="col-lg-12 address-section"
              style={{
                display: "flex",
                backgroundColor: "green",
                height: "40px",
                marginTop: "-10px",
              }}
            >
              {/* Branch 1 Address */}
              <div className="col-lg-8" style={{ marginLeft: "-50px" }}>
                <div className="header_info">
                  <ul className="footer-adress">
                    <li className="footer_address">
                      {" "}
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      <span>
                        {" "}
                        <a
                          href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA"
                          style={{ fontSize: "12px", color: "white" }}
                        >
                          {" "}
                          <span style={{ fontWeight: "bold", color: "white" }}>
                            Branch : 1
                          </span>{" "}
                          - M.T.K Reddy Building, #1, 2<sup>nd</sup> Floor,
                          Above Sangeetha Mobiles, opp. KLM Fashion Mall,
                          Marathahalli-37{" "}
                        </a>
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="header_info">
                  <ul className="footer-adress">
                    <li className="footer_address">
                      {" "}
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      <span>
                        {" "}
                        <a
                          href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA"
                          style={{ fontSize: "12px", color: "white" }}
                        >
                          {" "}
                          <span style={{ fontWeight: "bold", color: "white" }}>
                            Branch : 2
                          </span>{" "}
                          - Beside Kala Mandir, Marathahalli Bridge Signal
                          Bengaluru, Karnataka 560037{" "}
                        </a>
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-3 col-md-12 navbar-light">
            <div className="logo">
              {" "}
              <a href="/">
                <img
                  alt=""
                  className="logo-default"
                  src={Logo}
                  style={{ objectFit: "cover" }}
                />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="navbar-toggler-icon"></span>{" "}
            </button>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="navigation-wrap" id="filters">
              <nav className="navbar navbar-expand-lg navbar-light">
                {" "}
                <a className="navbar-brand" href="#">
                  Menu
                </a>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <button
                    className="close-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span>
                      <i className="fas fa-times-circle" aria-hidden="true"></i>
                    </span>
                  </button>

                  <ul
                    className="navbar-nav mr-auto"
                    style={{ marginLeft: "50px" }}
                  >
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="/">
                        {isMobile && <i className="fas fa-home"></i>}
                        Home <span className="sr-only">(current)</span>
                      </a>{" "}
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/aboutus">
                        {isMobile && <i className="fas fa-info-circle"></i>}{" "}
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/classblog">
                        {isMobile && <i className="fas fa-graduation-cap"></i>}{" "}
                        Courses
                      </a>{" "}
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/teacherblog">
                        {isMobile && (
                          <i className="fas fa-chalkboard-teacher"></i>
                        )}
                        Trainers
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/contact">
                        {isMobile && <i className="fas fa-envelope"></i>}Contact
                        Us
                      </a>
                    </li>
                  </ul>
                  {isMobile && (
                    <div className="col-lg-12 address-section">
                      {/* Branch 1 Address */}
                      <div className="col-lg-8">
                        <h5 style={{ color: "white", marginLeft: "6px" }}>
                          <h6>
                            <i className="fas fa-phone-alt"></i>{" "}
                            <span
                              style={{
                                // fontWeight: "bold",
                                color: "white",
                                marginLeft: "15px",
                              }}
                            >
                              Tel -<br></br>
                            </span>{" "}
                            <span style={{ fontSize: "13px" }}>
                              9900213083 /
                              {/* <span style={{ marginLeft: "20px" }}> */}{" "}
                              9845555716
                              {/* </span> */}
                            </span>
                          </h6>
                        </h5>
                        <h5 style={{ color: "white", marginLeft: "6px" }}>
                          <h6>
                            <i
                              className="fas fa-envelope"
                              aria-hidden="true"
                            ></i>{" "}
                            <span
                              style={{
                                // fontWeight: "bold",
                                color: "white",
                                marginLeft: "15px",
                              }}
                            >
                              Email -
                            </span>{" "}
                            <span style={{ fontSize: "13px" }}>
                              brilliantinfotechnic@gmail.com{" "}
                            </span>
                          </h6>
                        </h5>

                        {/* <div className="header_info">
                          <ul className="footer-adress">
                            <li
                              className="footer_address"
                              style={{ marginLeft: "15px" }}
                            >
                              {" "}
                              <i className="fas fa-phone-alt"></i>{" "}
                              <span>
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA"
                                  style={{ fontSize: "12px", color: "white" }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    Tel -
                                  </span>{" "}
                                  9900213083 / 9845555716{" "}
                                </a>
                              </span>{" "}
                            </li>
                            <li
                              className="footer_address"
                              style={{ marginLeft: "15px" }}
                            >
                              {" "}
                              <i
                                className="fas fa-envelope"
                                aria-hidden="true"
                              ></i>{" "}
                              <span>
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA"
                                  style={{ fontSize: "12px", color: "white" }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    Email -
                                  </span>{" "}
                                  brilliantinfotechnic@gmail.com{" "}
                                </a>
                              </span>{" "}
                            </li>
                          </ul>
                        </div> */}
                      </div>

                      <div className="col-lg-8">
                        <h3>Contact Info</h3>

                        <div className="header_info">
                          <ul className="footer-adress">
                            <li className="footer_address">
                              {" "}
                              <i className="fas fa-map-marker-alt"></i>{" "}
                              <span>
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA"
                                  style={{ fontSize: "12px", color: "white" }}
                                  id="footer-adressOne"
                                >
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      // marginLeft: "-15px",
                                    }}
                                  >
                                    Branch : 1 - <br />
                                  </span>{" "}
                                  M.T.K Reddy Building,
                                  <br /> #1, 2<sup>nd</sup> Floor,
                                  <br /> Above Sangeetha Mobiles, <br />
                                  opp. KLM Fashion Mall,
                                  <br /> Marathahalli - 560037, <br />{" "}
                                  Bengaluru.{" "}
                                </a>
                              </span>{" "}
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-8">
                          <div className="header_info">
                            <ul className="footer-adress">
                              <li
                                className="footer_address"
                                style={{ marginLeft: "-35px" }}
                              >
                                {" "}
                                <i className="fas fa-map-marker-alt"></i>{" "}
                                <span>
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA"
                                    style={{ fontSize: "12px", color: "white" }}
                                  >
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                        // marginLeft: "-15px",
                                      }}
                                    >
                                      Branch : 2 - <br />
                                    </span>{" "}
                                    Beside Kala Mandir,
                                    <br /> Marathahalli - 560037 <br />{" "}
                                    Bengaluru.{" "}
                                  </a>
                                </span>{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </nav>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="header_info">
              <div className="search">
                <a href="tel:9845555716">
                9845555716
                </a>
              </div>
              <div className="loginwrp">
                {userRole === "user" ? (
                  <>
                    <div id="profileSection">
                      <img
                        src="https://i.pinimg.com/736x/44/4b/d6/444bd6f7040c5ead15609750140c3713.jpg"
                        className="imageStyle"
                        onClick={handleDropdownToggle}
                      />
                      {isDropdownOpen && (
                        <div className="flex flex-column dropDownsection">
                          <ul className="flex flex-column gap-4 unOrderedList">
                            <li>
                              <a href="">
                                <i className="fas fa-user"></i> Profile
                              </a>
                            </li>
                            <li>
                              <a href="/userbookings">
                                <i className="fas fa-book"></i> Bookings
                              </a>
                            </li>
                            <li>
                              <a href="#" onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt"></i> Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <a href="/login">Login/Register</a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
