// import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import Header from "../Header/Header";
// import ROOT_URL from "../../Utilities/apiconfig";

// const Usersbooking = () => {
//   const user = useSelector((state) => state.auth.user);
//   const userId = user ? user.id : null;
//   const [bookings, setBookings] = useState([]);
//   const [courses, setCourses] = useState([]);

//   useEffect(() => {
//     const fetchBookings = async () => {
//       try {
//         const response = await axios.get(`${ROOT_URL}/allbookings`);
//         console.log("Bookings API response:", response.data);

//         if (Array.isArray(response.data.data)) {
//           const allBookings = response.data.data;
//           const userBookings = allBookings.filter(
//             (booking) => booking.userId === userId
//           );
//           console.log("Users bookings:", userBookings);

//           const coursePromises = userBookings.map((booking) =>
//             axios.get(`${ROOT_URL}/idbygetcourses`, {
//               headers: { id: booking.courseId },
//             })
//           );

//           const coursesData = await Promise.all(coursePromises);
//           console.log("Courses data:", coursesData);

//           setCourses(coursesData.map((response) => response.data));
//           setBookings(userBookings);
//         } else {
//           console.error(
//             "Invalid response data format: expected an array of bookings"
//           );
//         }
//       } catch (error) {
//         console.error("Error fetching bookings:", error);
//       }
//     };

//     fetchBookings();
//   }, [userId]);

//   return (
//     <>
//       <Header />
//       <div className="innerHeading-wrap">
//         <div className="container">
//           <h1>Booking Details</h1>
//         </div>
//       </div>
//       <div className="innerContent-wrap">
//         <div className="container">
//           <div className="classDetails-wrap">
//             {courses.map((course, index) => (
//               <div className="row" key={index}>
//                 <div className="col-lg-8">
//                   {/* Disable course information if payment status is not approved */}
//                   {bookings.map((booking) => (
//                     (booking.courseId === course.data.id && booking.paymentstatus === "approved") && (
//                       <div className="class_left" key={course.data.id}>
//                         <div className="class_Img">
//                           <img src={course.data.courseimage} alt="Course" />
//                           <div className="time_box">
//                             <span>{course.data.classtime}</span>
//                           </div>
//                         </div>
//                         <h3>{course.data.coursename}</h3>
//                         <p>{course.data.coursedescription}</p>
//                         <div className="sidebar-item">
//                           <ul className="class-details">
//                             <li>
//                               <div className="name">
//                                 <i className="fas fa-building" aria-hidden="true"></i>
//                                 Class Size
//                               </div>
//                               <div className="info">{course.data.coursesize}</div>
//                             </li>
//                             <li>
//                               <div className="name">
//                                 <i className="fas fa-calendar" aria-hidden="true"></i>
//                                 Course Duration
//                               </div>
//                               <div className="info">{course.data.duration}</div>
//                             </li>
//                             <li>
//                               <div className="name">
//                                 <i className="fas fa-clock" aria-hidden="true"></i>Class
//                                 Time
//                               </div>
//                               <div className="info">{course.data.classtime}</div>
//                             </li>
//                             <li>
//                               <div className="name">
//                                 <i className="fas fa-money-bill-alt"></i>Tuition Fee
//                               </div>
//                               <div className="info">{course.data.fees}</div>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     )
//                   ))}
//                 </div>
//                 {/* Display payment information for the current booking only if payment status is approved */}
//                 {bookings.map((booking, index) => (
//                   (booking.courseId === course.data.id && booking.paymentstatus === "approved") && (
//                     <div className="col-lg-4" key={index}>
//                       <div className="single-widgets widget_category">
//                         <h4>Payment Information</h4>
//                         <ul className="unorderList">
//                           <>
//                             <li>
//                               <a href="#">
//                                 Amount <span>{booking.amount}</span>
//                               </a>
//                             </li>
//                             <li>
//                               <a href="#">
//                                 Reference No <span>{booking.referenceNo}</span>
//                               </a>
//                             </li>
//                           </>
//                         </ul>
//                       </div>
//                     </div>
//                   )
//                 ))}
//                 {/* Display a message if payment status is "transit" */}
//                 {bookings.map((booking, index) => (
//                   (booking.courseId === course.data.id && booking.paymentstatus === "transit") && (
//                     <div className="col-lg-12" key={index}>
//                       <div className="single-widgets widget_category">
//                         <h4>Payment Information</h4>
//                         <p>Your payment is under review, We will update it very soon !</p>
//                       </div>
//                     </div>
//                   )
//                 ))}
//                 {bookings.map((booking, index) => (
//                   (booking.courseId === course.data.id && booking.paymentstatus === "rejected") && (
//                     <div className="col-lg-12" key={index}>
//                       <div className="single-widgets widget_category">
//                         <h4>Payment Information</h4>
//                         <p>Your payment has been rejected.</p>
//                       </div>
//                     </div>
//                   )
//                 ))}
//               </div>
//             ))}
//           </div>
//           {/* Classes Details End */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Usersbooking;
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Header from "../Header/Header";
import ROOT_URL from "../../Utilities/apiconfig";

const Usersbooking = () => {
  const user = useSelector((state) => state.auth.user);
  const userId = user ? user.id : null;
  const [bookings, setBookings] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${ROOT_URL}/allbookings`);
        console.log("Bookings API response:", response.data);

        if (Array.isArray(response.data.data)) {
          const allBookings = response.data.data;
          const userBookings = allBookings.filter(
            (booking) => booking.userId === userId
          );
          console.log("Users bookings:", userBookings);

          const coursePromises = userBookings.map((booking) =>
            axios.get(`${ROOT_URL}/idbygetcourses`, {
              headers: { id: booking.courseId },
            })
          );

          const coursesData = await Promise.all(coursePromises);
          console.log("Courses data:", coursesData);

          setCourses(coursesData.map((response) => response.data));
          setBookings(userBookings);
        } else {
          console.error(
            "Invalid response data format: expected an array of bookings"
          );
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [userId]);

  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Booking Details</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container">
          <div className="classDetails-wrap">
            {courses.map((course, index) => (
              <div className="row" key={index}>
                <div className="col-lg-8">
                  {/* Disable course information if payment status is not approved */}
                  {bookings.map((booking) => {
                    if (
                      booking.courseId === course.data.id &&
                      booking.paymentstatus === "approved"
                    ) {
                      return (
                        <div className="class_left" key={course.data.id}>
                          <div className="class_Img">
                            <img
                              src={course.data.courseimage}
                              alt="Course"
                            />
                            <div className="time_box">
                              <span>{course.data.classtime}</span>
                            </div>
                          </div>
                          <h3>{course.data.coursename}</h3>
                          <p>{course.data.coursedescription}</p>
                          <div className="sidebar-item">
                            <ul className="class-details">
                              <li>
                                <div className="name">
                                  <i
                                    className="fas fa-building"
                                    aria-hidden="true"
                                  ></i>
                                  Class Size
                                </div>
                                <div className="info">
                                  {course.data.coursesize}
                                </div>
                              </li>
                              <li>
                                <div className="name">
                                  <i
                                    className="fas fa-calendar"
                                    aria-hidden="true"
                                  ></i>
                                  Course Duration
                                </div>
                                <div className="info">
                                  {course.data.duration}
                                </div>
                              </li>
                              <li>
                                <div className="name">
                                  <i
                                    className="fas fa-clock"
                                    aria-hidden="true"
                                  ></i>
                                  Class Time
                                </div>
                                <div className="info">
                                  {course.data.classtime}
                                </div>
                              </li>
                              <li>
                                <div className="name">
                                  <i className="fas fa-money-bill-alt"></i>
                                  Tuition Fee
                                </div>
                                <div className="info">{course.data.fees}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                {/* Display payment information for the current booking only if payment status is approved */}
                {bookings.map((booking, index) => {
                  if (
                    booking.courseId === course.data.id &&
                    booking.paymentstatus === "approved"
                  ) {
                    return (
                      <div className="col-lg-4" key={index}>
                        <div className="single-widgets widget_category">
                          <h4>Payment Information</h4>
                          <ul className="unorderList">
                            <li>
                              <a href="#">
                                Amount <span>{booking.amount}</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                Reference No{" "}
                                <span>{booking.referenceNo}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  } else if (
                    booking.courseId === course.data.id &&
                    booking.paymentstatus === "transit"
                  ) {
                    return (
                      <div className="col-lg-12" key={index}>
                        <div className="single-widgets widget_category">
                          <h4>Payment Information</h4>
                          <p>
                            Your payment is under review, We will update it
                            very soon!
                          </p>
                        </div>
                      </div>
                    );
                  } else if (
                    booking.courseId === course.data.id &&
                    booking.paymentstatus === "rejected"
                  ) {
                    return (
                      <div className="col-lg-12" key={index}>
                        <div className="single-widgets widget_category">
                          <h4>Payment Information</h4>
                          <p>Your payment has been rejected.</p>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            ))}
          </div>
          {/* Classes Details End */}
        </div>
      </div>
    </>
  );
};

export default Usersbooking;
