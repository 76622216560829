import React, { useState } from "react";
import { useDispatch } from 'react-redux'; // Importing useDispatch from react-redux
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer/Footer";
import axios from "axios";
import { login } from '../../Redux/authactions'; // Importing the login action
import ROOT_URL from "../../Utilities/apiconfig";

const Login = () => {
  const dispatch = useDispatch(); // Initializing useDispatch hook
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    usernameoremail: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    axios
      .post(`${ROOT_URL}/login`, formData)
      .then((response) => {
        console.log("Login response:", response.data);
  
        setFormData({
          usernameoremail: "",
          password: "",
        });
  
        // Display toast message for 5 seconds
        toast.success(response.data.message, { autoClose: 3000 });
  
        // Determine the user role from the response data
        const userrole = response.data.data.role;
        dispatch(login(response.data.data)); // Dispatching the login action with the user role
        // Navigate based on the user role
        if (userrole === "admin") {
          navigate("/home");
        } else if (userrole === "user") {
          navigate("/");
        } else {
          console.error("Unknown user role:", userrole);
        }
      })
      .catch((error) => {
        console.error("Error logging in:", error);
        // Display toast message for login failure
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
  };

  const handleRegister = () => {
    navigate("/register");
  };

  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Login</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container">
          <div className="login-wrap ">
            <div className="contact-info login_box">
              <h3>New Students</h3>
              <p>
                If you don't have an account, please proceed by clicking the
                following button to continue first-time registration.
              </p>
              <div className="form-group">
                <button
                  onClick={handleRegister}
                  type="submit"
                  className="default-btn btn send_btn"
                  style={{marginTop:"15px"}}
                >
                  Create Account <span></span>
                </button>
              </div>
              <div className="contact-form loginWrp">
                <h3>Login Users</h3>
                <p>Learning is the only thing the mind never exhausts, never fears, and never regrets.</p>
                <form id="contactForm" noValidate="">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="usernameoremail"
                          className="form-control"
                          placeholder="Email or Username"
                          value={formData.usernameoremail}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <button
                          type="submit"
                          className="default-btn btn send_btn"
                          onClick={handleSubmit}
                        >
                          {" "}
                          Log in to my account <span></span>
                        </button>
                      </div>
                      <div className="form-group">
                        <div className="forgot_password">
                          <a href="changepassword">Reset My Password</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
};

export default Login;





