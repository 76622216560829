import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ROOT_URL from "../../Utilities/apiconfig";

const Enrollment = () => {
  const [enroll, setenroll] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [enrollPerPage] = useState(5); // Number of enroll per page
  const [pageNumberLimit] = useState(3); // Number of page numbers to display
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [deletingBookingId, setdeletingBookingId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch enroll data

    fetchenroll();
  }, []);

  const fetchenroll = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/getenroll`);
      const responseData = response.data;

      // Check if any enroll are found
      if (responseData.isError || !responseData.data) {
        // Handle error or empty response
        console.error("Error fetching enroll:", responseData.message);
        return;
      }

      // Extract enroll from the response data
      const enroll = responseData.data;

      // Set the enroll state with the extracted enroll
      setenroll(enroll);
    } catch (error) {
      console.error("Error fetching enroll:", error);
    }
  };

  // Get current enroll
  const indexOfLastBooking = currentPage * enrollPerPage;
  const indexOfFirstBooking = indexOfLastBooking - enrollPerPage;
  const currentEnrollments = enroll.slice(
    indexOfFirstBooking,
    indexOfLastBooking
  );

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);

    if (pageNumber > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }

    if (pageNumber < minPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleDeleteBooking = async () => {
    try {
      if (deletingBookingId) {
        // If deletingBookingId exists, execute delete by ID API
        const response = await axios.delete(`${ROOT_URL}/enrollidbydeleting`, {
          headers: { id: deletingBookingId },
        });
        // Display toast message returned from the backend
        toast.success(response.data.message);
        // After deletion, fetch the updated list of enroll
        const updatedenroll = enroll.filter(
          (trainer) => trainer.id !== deletingBookingId
        );
        setenroll(updatedenroll);
      } else {
        // If deletingBookingId does not exist, execute delete all API
        const response = await axios.delete(`${ROOT_URL}/allenrolldeleting`);
        // Display toast message returned from the backend
        toast.success(response.data.message);
        // After deletion, set the enroll state to an empty array
        setenroll([]);
      }
    } catch (error) {
      if (error.response) {
        // If the backend returns an error response
        const errorMessage =
          error.response.data.message || "Error deleting trainer";
        // Display the error message in the toast notification
        toast.error(errorMessage);
      } else {
        // If there is a network error or other unexpected error
        toast.error("Error deleting trainer");
        console.error("Error deleting trainer:", error);
      }
    }
    // Close the modal after deletion
    setIsDeleteModalOpen(false);
    setdeletingBookingId(null);
  };

  // Rendering page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(enroll.length / enrollPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    if (number >= minPageNumberLimit && number <= maxPageNumberLimit) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <a href="#" className="page-link" onClick={() => paginate(number)}>
            {number}
          </a>
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="table-wrapper">
      <div className="table-title">
        <div className="row">
          <div className="col-sm-6 p-0 flex justify-content-lg-start justify-content-center">
            <h2 className="ml-lg-2">Manage Enroll</h2>
          </div>
          <div class="col-sm-6 p-0 flex justify-content-lg-end justify-content-center">
            <a
              href="#"
              class="btn btn-danger"
              data-toggle="modal"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              <i class="material-icons">&#xE15C;</i>
              <span>Delete All</span>
            </a>
            <a href="#" class="btn btn-primary" onClick={fetchenroll}>
              <i class="material-icons">&#xE5D5;</i>
              <span>Refresh</span>
            </a>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <span className="custom-checkbox" />
                <input type="checkbox" id="selectAll" />
                <label htmlFor="selectAll"></label>
              </th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Course Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentEnrollments.map((enrollment) => (
              <tr key={enrollment.id}>
                <td>
                  <span className="custom-checkbox" />
                  <input
                    type="checkbox"
                    id={`checkbox${enrollment.id}`}
                    name="option[]"
                    value={enrollment.id}
                  />
                  <label htmlFor={`checkbox${enrollment.id}`}></label>
                </td>
                <td>{enrollment.firstname}</td>
                <td>{enrollment.lastname}</td>
                <td>{enrollment.email}</td>
                <td>{enrollment.phonenumber}</td>
                <td>{enrollment.coursedetails}</td>
                <td>
                  <a
                    href="#"
                    className="delete"
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setdeletingBookingId(enrollment.id);
                    }}
                  >
                    <i
                      className="material-icons"
                      data-toggle="tooltip"
                      title="Delete"
                    >
                      &#xE872;
                    </i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>

      <div className="clearfix">
        <div className="hint-text">
          Showing <b>{indexOfFirstBooking + 1}</b> to{" "}
          <b>{Math.min(indexOfLastBooking, enroll.length)}</b> out of{" "}
          <b>{enroll.length}</b>
        </div>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a href="" onClick={() => paginate(currentPage - 1)}>
              Previous
            </a>
          </li>
          {renderPageNumbers}
          <li
            className={`page-item ${
              currentPage === Math.ceil(enroll.length / enrollPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <a href="" onClick={() => paginate(currentPage + 1)}>
              Next
            </a>
          </li>
        </ul>
      </div>

      <div
        className={`modal fade ${isDeleteModalOpen ? "show" : ""}`}
        id="deleteEmployeeModal"
        role="dialog"
        style={{ display: isDeleteModalOpen ? "block" : "none" }}
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Delete Enroll</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete this Records</p>
              <p class="text-warning">
                <small>this action Cannot be Undone,</small>
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-success"
                onClick={handleDeleteBooking}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enrollment;

