import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    usernameoremail: "",
    oldpassword: "",
    newpassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

      // Check if any of the required fields are empty
  if (!formData.usernameoremail || !formData.oldpassword || !formData.newpassword) {
    toast.error("All fields are required");
    return;
  }

    axios
      .post("https://brilliant.goldenkarat.in/api/passwordreset", formData)
      .then((response) => {
        console.log(response.data.message);

        setFormData({
          usernameoremail: "",
          oldpassword: "",
          newpassword: "",
        });
        // Display toast message for 5 seconds
        toast.success(response.data.message, { autoClose: 3000 });

        // Navigate to the home page after 5 seconds
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        // Display toast message for password change failure
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
  };

  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Reset Password</h1>
        </div>
      </div>

      <div className="innerContent-wrap">
        <div className="container">
          <div className="login-wrap">
            <div className="contact-info login_box">
              <div className="contact-form loginWrp registerWrp">
                <form id="contactForm" noValidate>
                  <div className="form_set">
                    <h3>Account Information</h3>
                    <div className="form-group">
                      <input
                        type="text"
                        name="usernameoremail"
                        className="form-control"
                        placeholder="Email or Username"
                        value={formData.usernameoremail}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="oldpassword"
                        className="form-control"
                        placeholder="Old Password"
                        value={formData.oldpassword}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="newpassword"
                        className="form-control"
                        placeholder="New Password"
                        value={formData.newpassword}
                        onChange={handleChange}
                      />
                      <div className="passnote">
                        Note: Password must be a minimum of 8 characters
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="default-btn btn send_btn"
                        onClick={handleSubmit}
                      >
                        Reset Password <span></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;