


import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const Updateusers = () => {
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/getuserbyid`, {
        headers: { id: id },
      });
      console.log("user data:", response.data);
      const userData = response.data;

      setPassword(userData.data.password);
      setFirstname(userData.data.firstname);
      setLastname(userData.data.lastname);
      setPhonenumber(userData.data.phonenumber);
      setRole(userData.data.role);

      console.log(password, firstname, lastname, phonenumber, role);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const updateUserData = async () => {
    try {
      const data = {
        password,
        firstname,
        lastname,
        phonenumber,
        role
      };

      await axios.put(`${ROOT_URL}/updateuserbyid`, data, {
        headers: {
          id: id,
          "Content-Type": "application/json"
        }
      });

      toast.success("User data updated successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.error("Failed to update user data. Please try again later.");
    }
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Update User Data</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="passwordInput">Password</label>
          <input
            type="text"
            className="form-control"
            placeholder="Password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="firstnameInput">First Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="First Name"
            id="firstname"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="lastnameInput">Last Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Last Name"
            id="lastname"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="phonenumberInput">Phone Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="Phone Number"
            id="phonenumber"
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="roleInput">Role</label>
          <input
            type="text"
            className="form-control"
            placeholder="Role"
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          />
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={updateUserData}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Updateusers;
