import React from "react";

const Newsletter = () => {
  return (
    <div className="newsletter-wrap ">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="title">
              <h1>Newsletter</h1>
            </div>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div className="col-lg-6">
            <div className="news-info">
              <form>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Email Address"
                  />
                  <div className="form_icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                </div>
                <button className="sigup">Sign Up</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
