import React, { useRef, useEffect, useState } from "react";
import "./home.css";
import BrilliantLogo from "../Images/logo.png";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/authactions";
import { Outlet } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logout());
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Create a ref for the XP menubar element
  const xpMenubarRef = useRef(null);

  const handleSidebarClose = () => {
    document.getElementById("sidebar").classList.remove("active");
    document.getElementById("content").classList.remove("active");
    document.getElementById("sidebar").classList.remove("show-nav");
    document
      .getElementsByClassName("body-overlay")[0]
      .classList.remove("show-nav");
    setIsSidebarOpen(false); // Update state to close the sidebar
  };

  useEffect(() => {
    // Function to toggle classes when the XP menubar is clicked
    const handleClick = () => {
      document.getElementById("sidebar").classList.toggle("active");
      document.getElementById("content").classList.toggle("active");
      document.getElementById("sidebar").classList.toggle("show-nav");
      document
        .getElementsByClassName("body-overlay")[0]
        .classList.toggle("show-nav");
      setIsSidebarOpen(!isSidebarOpen);
    };

    // Attach click event listener to XP menubar when the component mounts
    if (xpMenubarRef.current) {
      xpMenubarRef.current.addEventListener("click", handleClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (xpMenubarRef.current) {
        xpMenubarRef.current.removeEventListener("click", handleClick);
      }
    };
  }, [isSidebarOpen]);

  return (
    <div class="wrapper">
      <div class="body-overlay"></div>

      <div id="sidebar">
        <div class="sidebar-header">
          <h3>
            <img src={BrilliantLogo} class="img-fluid" />
            {/* <span>Brilliant Academy</span> */}
            <span
              class={`material-icons cross-icon d-lg-none ${
                isSidebarOpen ? "visible" : "hidden"
              }`}
              onClick={handleSidebarClose}
            >
              close
            </span>
          </h3>
        </div>
        <ul class="list-unstyled component m-0">
          <li class="active">
            <a href="" class="dashboard">
              <i class="material-icons">dashboard</i>Dashboard{" "}
            </a>
          </li>
          <li class="">
            <a href="/home/users" class="">
              <i class="material-icons">group</i>Users{" "}
            </a>
          </li>
          <li class="">
            <a href="/home/courses" class="">
              <i class="material-icons">class</i>Courses{" "}
            </a>
          </li>

          <li class="">
            <a href="/home/bookings" class="">
              <i class="material-icons">event_available</i>Bookings{" "}
            </a>
          </li>
          <li class="">
            <a href="/home/trainers" class="">
              <i class="material-icons">person</i>Trainers{" "}
            </a>
          </li>
          <li class="">
            <a href="/home/contacts" class="">
              <i class="material-icons">contacts</i>Contacts{" "}
            </a>
          </li>
          <li class="">
            <a href="/home/galleryimages" class="">
              <i class="material-icons">collections</i>Gallery{" "}
            </a>
          </li>
          <li class="">
            <a href="/home/sliderdetails" class="">
              <i class="material-icons">slideshow</i>Slider{" "}
            </a>
          </li>
          <li class="">
            <a href="/home/enrollment" class="">
              <i class="material-icons">add_circle</i>Enroll{" "}
            </a>
          </li>
          <li class="">
            <a href="/home/testimonialdetails" class="">
              <i class="material-icons">star</i>Testimonials{" "}
            </a>
          </li>
        </ul>
      </div>
      <div id="content">
        <div class="top-navbar1">
          <div class="xd-topbar">
            <div class="row">
              <div class="col-2 col-md-1 col-lg-1 order-2 order-md-1 align-self-center">
                <div class="xp-menubar" ref={xpMenubarRef}>
                  <span class="material-icons text-white">
                    signal_cellular_alt
                  </span>
                </div>
              </div>

              <div class="col-md-5 col-lg-3 order-3 order-md-2">
                <div class="xp-searchbar">
                  <form>
                    <div class="input-group">
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Search"
                      />
                      <div class="input-group-append">
                        <button class="btn" type="submit" id="button-addon2">
                          Go
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="col-10 col-md-6 col-lg-8 order-1 order-md-3">
                <div class="xp-profilebar text-right">
                  <nav class="navbar1 p-0">
                    <ul class="nav navbar1-nav1 flex-row justify-content-end">
                      <li class="dropdown nav-item1 active">
                        <a class="nav-link1" href="#" data-toggle="dropdown">
                          <span class="material-icons">notifications</span>
                          <span class="notification">4</span>
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <a href="#">You Have 4 New Messages</a>
                          </li>
                          <li>
                            <a href="#">You Have 4 New Messages</a>
                          </li>
                          <li>
                            <a href="#">You Have 4 New Messages</a>
                          </li>
                          <li>
                            <a href="#">You Have 4 New Messages</a>
                          </li>
                        </ul>
                      </li>

                      <li class="nav-item1">
                        <a class="nav-link1" href="#">
                          <span class="material-icons">question_answer</span>
                        </a>
                      </li>

                      <li class="dropdown nav-item1">
                        <a class="nav-link1" href="#" data-toggle="dropdown">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQvq4Q0qwwAIhiWUfQFS8Mqh7E78xGIRVkfXlayDAe5Ge9QSoETQJLGMtW1f4j2p7HQ3U&usqp=CAU"
                            style={{ width: "40px", borderRadius: "50%" }}
                          />
                          <span class="xp-user-live"></span>
                        </a>
                        <ul class="dropdown-menu small-menu">
                          <li>
                            <a href="#">
                              <span class="material-icons">person_outline</span>
                              Profile
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span class="material-icons">settings</span>
                              Settings
                            </a>
                          </li>
                          <li>
                            <a href="" onClick={handleLogout}>
                              <span class="material-icons">logout</span>
                              Logout
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div class="xp-breadcrumbbar text-center">
              <h4 class="page-title">Dashboard</h4>
              <ol class="breadcrumb">
              </ol>
            </div>
          </div>
        </div>
        <div class="main-content">
          <div class="row">
            <div class="col-md-12">
              <Outlet />
            </div>
            <div
              class="modal fade"
              tabindex="-1"
              id="addEmployeeModal"
              role="dialog"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Add Employees</h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group">
                      <label>Name</label>
                      <input type="text" class="form-control" required />
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <input type="emil" class="form-control" required />
                    </div>
                    <div class="form-group">
                      <label>Address</label>
                      <textarea class="form-control" required></textarea>
                    </div>
                    <div class="form-group">
                      <label>Phone</label>
                      <input type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="button" class="btn btn-success">
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              tabindex="-1"
              id="editEmployeeModal"
              role="dialog"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Edit Employees</h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group">
                      <label>Name</label>
                      <input type="text" class="form-control" required />
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <input type="emil" class="form-control" required />
                    </div>
                    <div class="form-group">
                      <label>Address</label>
                      <textarea class="form-control" required></textarea>
                    </div>
                    <div class="form-group">
                      <label>Phone</label>
                      <input type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="button" class="btn btn-success">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              tabindex="-1"
              id="deleteEmployeeModal"
              role="dialog"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Delete Employees</h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>Are you sure you want to delete this Records</p>
                    <p class="text-warning">
                      <small>this action Cannot be Undone,</small>
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="button" class="btn btn-success">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
