import React,{useState} from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ROOT_URL from "../../Utilities/apiconfig";

const Contact = () => {


  const [formData, setformData] = useState({
    email: "",
    firstname: "",
    lastname:"",
    phonenumber:"",
    message:"",
  });


const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("https://brilliant.goldenkarat.in/api/contactpost", formData)
      .then((response) => {
        console.log(response.data.message);

        setformData({
          email: "",
          firstname: "",
          lastname:"",
          phonenumber:"",
          message:"",
        });
         // Display toast message for 5 seconds
         toast.success(response.data.message, { autoClose: 3000 });
        


      })
      .catch((error) => {
        console.error("Error logging in :", error);
         // Display toast message for login failure
         toast.error(error.response.data.message, { autoClose: 3000 });
      });
  };


  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container">
          <div className="cont_info ">
            <div className="row">
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="address-item style">
                  <div className="address-icon">
                    {" "}
                    <i className="fas fa-phone-alt"></i>{" "}
                  </div>
                  <div className="address-text">
                    <h3 className="contact-title">Call Us</h3>
                    <ul className="unorderList">
                      <li>
                        <a href="tel:77701234567"> 9900213083 / 9900164477</a>
                      </li>
                      <li>
                        <a href="tel:77701234567">080-40942836</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="address-item style">
                  <div className="address-icon">
                    {" "}
                    <i className="far fa-envelope"></i>{" "}
                  </div>
                  <div className="address-text">
                    <h3 className="contact-title">Mail Us</h3>
                    <ul className="unorderList">
                      <li>
                        <a href="#">brilliantinfotechnic@gmail.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 sm-mb-30">
                <div className="address-item">
                  <div className="address-icon">
                    {" "}
                    <i className="far fa-clock"></i>{" "}
                  </div>
                  <div className="address-text">
                    <h3 className="contact-title">Opening Hours</h3>
                    <ul className="unorderList">
                      <li>Mon - Fri : 09:00 AM - 08:00 PM</li>
                      <li>Sat - Sun : Closed</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="address-item">
                  <div className="address-icon">
                    {" "}
                    <i className="fas fa-map-marker-alt"></i>{" "}
                  </div>
                  <div className="address-text">
                    <h3 className="contact-title">Address</h3>
                    <p>  M.T.K Reddy BUilding, #1, 2<sup>nd</sup> Floor, Beside
                      Sangeetha Mobiles AND SBI Bank Marathahalli Bridge Signal
                      Bengaluru, Karnataka 560037 India</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="login-wrap">
                <div className="contact-info login_box">
                  <div className="contact-form loginWrp registerWrp">
                    <form id="contactForm" novalidate="">
                      <h3>Get In Touch</h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="firstname"
                              className="form-control"
                              placeholder="First Name"
                              value={formData.firstname}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="lastname"
                              className="form-control"
                              placeholder="Last Name"
                              value={formData.lastname}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              placeholder="Email Address"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="number"
                              name="phonenumber"
                              className="form-control"
                              placeholder="Phone Number"
                              value={formData.phonenumber}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                            type="text"
                            name="message"
                              className="form-control"
                              placeholder="Message"
                              value={formData.message}
                              onChange={handleChange}
                              
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="default-btn btn send_btn"
                              onClick={handleSubmit}
                            >
                              {" "}
                              Submit <span></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
            <div className="col-lg-5">
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2301607043073!2d77.69811747489271!3d12.95711888735693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1334129c1523%3A0x8cb028f0a2de3de6!2sBrilliant%20Academy!5e0!3m2!1sen!2sin!4v1709540542330!5m2!1sen!2sin"
                  width="100%"
                  height="511"
                  frameborder="0"
                  style={{border:"0"}}
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Contact;
