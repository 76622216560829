import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ROOT_URL from "../../Utilities/apiconfig";
import axios from "axios"; // Import axios
import { useParams } from "react-router-dom";

const Teacherdetails = () => {
  const { id } = useParams();
  const [trainerDetails, setTrainerDetails] = useState(null);
  const [address, setAddress] = useState();
  const [phonenumber, setphonenumber] = useState();
  const [email, setemail] = useState();
  const [teaching, setteaching] = useState();
  const [communication, setcommunication] = useState();
  const [support, setsupport] = useState();

  useEffect(() => {
    async function fetchTrainerDetails() {
      try {
        // Fetch trainer details from the backend API
        const response = await axios.get(`${ROOT_URL}/byidtrainersget`, {
          headers: {
            trainerid: id, // Replace "your_trainer_id_here" with the actual trainer ID
          },
        });
        setTrainerDetails(response.data.data);
        setAddress(response.data.data.address);
        setphonenumber(response.data.data.phonenumber);
        setemail(response.data.data.email);
        setteaching(response.data.data.teaching);
        setcommunication(response.data.data.communication);
        setsupport(response.data.data.support)

      } catch (error) {
        console.error("Error fetching trainer details:", error);
      }
    }

    fetchTrainerDetails();
  }, []);
  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Teacher Details</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container mb-4">
          <div className="classDetails-wrap">
            <div className="row">
              <div className="col-lg-4">
                <div className="teacher_left">
                  <div className="teacher_delImg">
                    {trainerDetails && (
                      <img
                        src={trainerDetails.trainerimage}
                        alt={trainerDetails.trainername}
                        style={{ border: "1px solid gray", objectFit: "cover" }}
                      />
                    )}
                  </div>
                </div>
                <ul className="social-default ">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i
                        className="fab fa-google-plus-g"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest-p" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
                <ul className="teacher-address ">
                  <li>
                    <span>
                      <i className="fas fa-home" aria-hidden="true"></i>
                    </span>
                    {address}
                  </li>
                  <li>
                    <span>
                      <i className="fas fa-phone-alt" aria-hidden="true"></i>
                    </span>
                    {phonenumber}
                  </li>
                  <li>
                    <span>
                      <i className="fas fa-envelope" aria-hidden="true"></i>
                    </span>
                    {email}
                  </li>
                  <li>
                    <span>
                      <i className="fas fa-globe" aria-hidden="true"></i>
                    </span>
                    www.brilliantacademys.com
                  </li>
                </ul>
              </div>
              <div className="col-lg-8">
                <div className="teacher_del ">
                  {trainerDetails && (
                    <>
                      <h3>{trainerDetails.trainername}</h3>
                      <div className="designation">
                        {trainerDetails.trainercourse}
                      </div>
                      <p>{trainerDetails.description}</p>
                      <div className="progress-skill">
                        <h2>Personal Skills</h2>
                        <div
                          className="progress-wrap"
                          data-animation-section="progress-line"
                        >
                          <p className="progress-head">
                            Teaching <span>{teaching}%</span>
                          </p>
                          <div className="progress-line-wrap">
                            <div
                              className="progress-line color-1"
                              data-animation-block="is-vision"
                              data-value={teaching}
                              style={{ width: `${teaching}%` }}
                            ></div>
                          </div>
                        </div>
                        <div
                          className="progress-wrap"
                          data-animation-section="progress-line"
                        >
                          <p className="progress-head">
                            Speaking <span>{communication}%</span>
                          </p>
                          <div className="progress-line-wrap">
                            <div
                              className="progress-line color-2"
                              data-animation-block="is-vision"
                              data-value={communication}
                              style={{ width: `${communication}%` }}
                            ></div>
                          </div>
                        </div>
                        <div
                          className="progress-wrap"
                          data-animation-section="progress-line"
                        >
                          <p className="progress-head">
                            Student's Well-being <span>{support}%</span>
                          </p>
                          <div className="progress-line-wrap">
                            <div
                              className="progress-line color-4"
                              data-animation-block="is-vision"
                              data-value={support}
                              style={{ width: `${support}%` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
      </div>

      <Footer />
    </>
  );
};

export default Teacherdetails;
