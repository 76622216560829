import React from "react";
import Rootstock from "./Components/Navigation/Rootstock";

function App() {
  return (
    <>
      <Rootstock />
    </>
  );
}

export default App;
