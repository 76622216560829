import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const Updategalleryimages = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { id } = useParams();
  const [formData, setFormData] = useState({
    galleryimage: null,
    imagename: null,
  });

  useEffect(() => {
 

    fetchGalleryImages();
  }, [id]);

  const fetchGalleryImages = async () => {
    try {
      const response = await axios.get(
        `${ROOT_URL}/idbygetgallery`,
        { headers: { id: id } }
      );
      console.log("courses data:", response.data);
      const coursesData = response.data;

      // Populate formData with fetched data
      setFormData(prevState => ({
          ...prevState,
        galleryimage: coursesData.data.galleryimage,
        imagename: coursesData.data.imagename,
      }));
      setImageUrl(coursesData.data.galleryimage);
      console.log(formData)
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };




  ///////updatebyId courses/////


  const updateCourse = async () => {
    try {
      const formDataForUpdate = new FormData();
      formDataForUpdate.append("galleryimage", image);

      await axios.put(
        `${ROOT_URL}/idbyupdategallery`,
        formDataForUpdate,
        {
          headers: {
            id: id,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Gallery Images updated successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error("Error updating gallery images:", error);
      toast.error("Failed to update Gallery Images. Please try again later.");
    }
  };

  





  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
  
    if (selectedImage) {
      setImage(selectedImage);
  
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };
  

  const handleCancelImage = () => {
    setImage(null);
    setImageUrl("");
  };


  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Update Courses</h5>
        <div
          className="border border-gray rounded p-4 mb-3"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
            <h6 className="text-primary mb-3">Add Image</h6>
            <div className="input-group mb-3">
              <label htmlFor="inputGroupFile02" className="form-control">
                Choose File
                <input
                  type="file"
                  className="d-none"
                  id="inputGroupFile02"
                  name="galleryimage"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {imageUrl  && (
            <div
              className="mt-3"
              style={{
                position: "relative",
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Uploaded"
                  style={{ maxWidth: "20%" }}
                />
                <button
                  onClick={handleCancelImage}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button type="button" className="btn btn-primary mt-2"  onClick={updateCourse}>
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Updategalleryimages;

