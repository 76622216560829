import React from "react";

const Programs = () => {
  return (
    <div className="blog-wrap flight-wrap ">
      <div className="container">
        <div className="title">
          <h1> Our Programs </h1>
        </div>
        <ul className="row unorderList">
          <li className="col-lg-4">
            <div className="blog_box">
              <div className="blogImg">
                <img src="assets/images/program2.jpg" alt=""  style={{ objectFit: "cover" }}/>
              </div>
              <div className="path_box">
                <h3>
                  <a href="#">Software Courses </a>
                </h3>
                <p>
                  Software development courses teach us the art of creating,
                  designing, deploying, and supporting computer software,
                  including system software, programming software, or
                  application software.
                </p>
              </div>
            </div>
          </li>
          <li className="col-lg-4">
            <div className="blog_box">
              <div className="blogImg">
                <img src="assets/images/program1.png" alt=""  style={{ objectFit: "cover" }}/>
              </div>
              <div className="path_box">
                <h3>
                  <a href="#">Spoken English Course</a>
                </h3>
                <p>
                  It is a short-term course for the youth to help improve their
                  English communication and soft skills. It has been designed in
                  a way to help learners gain the confidence to speak English
                  fluently.
                </p>
              </div>
            </div>
          </li>
          <li className="col-lg-4">
            <div className="blog_box">
              <div className="blogImg">
                <img src="assets/images/program3.jpg" alt=""  style={{ objectFit: "cover" }}/>
              </div>
              <div className="path_box">
                <h3>
                  <a href="#">Group & Civil Services</a>
                </h3>
                <p>
                Civil services exams are competitive examinations held by various countries to recruit candidates for civil services positions in government departments. These exams are designed to assess the knowledge, skills, and aptitude of candidates to serve in administrative roles.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Programs;
