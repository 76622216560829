import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const Updatetestimonial = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { id } = useParams();
  const [formData, setFormData] = useState({
    testimonialname: "",
    testimonialimage: null,
    rating: "",
    testimonialdescription: "",
    imagename: null,
  });

  useEffect(() => {
    fetchtestimonials();
  }, [id]);

  const fetchtestimonials = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/idbygettestimonials`, {
        headers: { id: id },
      });
      console.log("testimonials data:", response.data);
      const testimonialsData = response.data;

      // Populate formData with fetched data
      setFormData((prevState) => ({
        ...prevState,
        testimonialname: testimonialsData.data.testimonialname,
        testimonialimage: testimonialsData.data.testimonialimage,
        rating: testimonialsData.data.rating,
        testimonialdescription: testimonialsData.data.testimonialdescription,
        imagename: testimonialsData.data.imagename,
      }));
      setImageUrl(testimonialsData.data.testimonialimage);
      console.log(formData);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  };

  ///////updatebyId testimonials/////

  const updateTestimonial = async () => {
    try {
      const formDataForUpdate = new FormData();
      formDataForUpdate.append("testimonialname", formData.testimonialname);
      formDataForUpdate.append("rating", formData.rating);
      formDataForUpdate.append("testimonialdescription", formData.testimonialdescription);
      formDataForUpdate.append("testimonialimage", image);

      await axios.put(`${ROOT_URL}/idbyupdatetestimonial`, formDataForUpdate, {
        headers: {
          id: id,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Testimonial updated successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error("Error updating testimonial:", error);
      toast.error("Failed to update testimonial. Please try again later.");
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);

      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImageUrl("");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Update Testimonials</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Testimonial Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Testimonial Name"
            id="testimonialname"
            name="testimonialname"
            value={formData.testimonialname}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Rating</label>
          <input
            type="text"
            className="form-control"
            placeholder="Rating"
            id="rating"
            name="rating"
            value={formData.rating}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Testimonial Description</label>
          <input
            type="text"
            className="form-control"
            placeholder="Testimonial Description"
            id="testimonialdescription"
            name="testimonialdescription"
            value={formData.testimonialdescription}
            onChange={handleChange}
          />
        </div>
        <div
          className="border border-gray rounded p-4 mb-3"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
            <h6 className="text-primary mb-3">Add Image</h6>
            <div className="input-group mb-3">
              <label htmlFor="inputGroupFile02" className="form-control">
                Choose File
                <input
                  type="file"
                  className="d-none"
                  id="inputGroupFile02"
                  name="testimonialimage"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {imageUrl && (
            <div
              className="mt-3"
              style={{
                position: "relative",
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Uploaded"
                  style={{ maxWidth: "20%" }}
                />
                <button
                  onClick={handleCancelImage}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={updateTestimonial}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Updatetestimonial;
