import React from "react";

const Testimonials = () => {
  return (
    <div className="testimonials-wrap ">
      <div className="container">
        <div className="title">
          <p>Testimoinials</p>
          <h1> What Students Say </h1>
        </div>
        <ul className="owl-carousel testimonials_list unorderList">
          <li className="item">
            <div className="testimonials_sec">
              <div className="client_box">
                <div className="clientImg">
                  <img alt="" src="assets/images/testimonial2.png" style={{objectFit: "cover"}} />
                </div>
                <ul className="unorderList starWrp">
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                </ul>
              </div>
              <p>
                It's such good institute. I have joined SAP FICO . Training was
                excellent with good interaction. Knowledge sharing is good.
                Recording facility is excellent for revising. Course was
                practically and informative. Krishna mohan Sir is enthusiastic
                and really aware of what he is explaining.
              </p>
              <h3>Nagaraj M Chakrasali</h3>
              <div className="quote_icon">
                <i className="fas fa-quote-left"></i>
              </div>
            </div>
          </li>
          <li className="item">
            <div className="testimonials_sec">
              <div className="client_box">
                <div className="clientImg">
                  <img alt="" src="assets/images/comment-img-2.png" style={{objectFit: "cover"}}/>
                </div>
                <ul className="unorderList starWrp">
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                </ul>
              </div>
              <p>
                I joined for SAP MM course...... Training session is very well
                and they guid us very well.... Anyone wants to learn new courses
                and start they should join here..{" "}
              </p>
              <h3>Sreerupa Sengupta</h3>
              <div className="quote_icon">
                <i className="fas fa-quote-left"></i>
              </div>
            </div>
          </li>
          <li className="item">
            <div className="testimonials_sec">
              <div className="client_box">
                <div className="clientImg">
                  <img alt="" src="assets/images/comment-img-3.png" style={{objectFit: "cover"}}/>
                </div>
                <ul className="unorderList starWrp">
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                </ul>
              </div>
              <p>
                I have taken core Java and advanced Java Savitha mam was my
                teacher and she taught me so well. Even when I had doubts she
                explained me more than twice until I get Thanks to mohan sir and
                savitha mam for this journey and I suggest u guys to join and
                they train you well
              </p>
              <h3>Teju Ramesh</h3>
              <div className="quote_icon">
                <i className="fas fa-quote-left"></i>
              </div>
            </div>
          </li>
          <li className="item">
            <div className="testimonials_sec">
              <div className="client_box">
                <div className="clientImg">
                  <img alt="" src="assets/images/testimonial1.png" style={{objectFit: "cover"}} />
                </div>
                <ul className="unorderList starWrp">
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                </ul>
              </div>
              <p>
                I have joined saf fico class teaching skills are very good
                brilliant academy providing excellent I hope it help too future
                bcz lecture provided. good class
              </p>
              <h3>Rahul Hb</h3>
              <div className="quote_icon">
                <i className="fas fa-quote-left"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Testimonials;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import ROOT_URL from "../../Utilities/apiconfig";

// const Testimonials = () => {
//   const [testimonials, setTestimonials] = useState([]);

//   useEffect(() => {
//     const fetchTestimonials = async () => {
//       try {
//         const response = await axios.get(
//           `${ROOT_URL}/gettestimonials`
//         );
//         setTestimonials(response.data);
//       } catch (error) {
//         console.error("Error fetching testimonials:", error);
//       }
//     };

//     fetchTestimonials();
//   }, []);


//    // Function to generate an array of stars based on the rating
//    const generateStars = (rating) => {
//     const fullStars = Array.from({ length: rating }, (_, index) => (
//       <i key={index} className="fas fa-star" style={{color: "#ffeb00"}}></i>
//     ));
//     const emptyStars = Array.from({ length: 5 - rating }, (_, index) => (
//       <i key={index + rating} className="far fa-star" style={{color: "#ffeb00"}}></i>
//     ));
//     return [...fullStars, ...emptyStars];
//   };

//   return (
//     <div className="testimonials-wrap ">
//       <div className="container">
//         <div className="title">
//           <p>Testimoinials</p>
//           <h1> What Students Say </h1>
//         </div>
//         <ul className="owl-carousel testimonials_list unorderList">
//           {testimonials.map((testimonial, index) => (
//             <li key={index} className="item">
//               <div className="testimonials_sec">
//                 <div className="client_box">
//                   <div className="clientImg">
//                     <img
//                       alt=""
//                       src={testimonial.testimonialimage}
//                       style={{ objectFit: "cover" }}
//                     />
//                   </div>
//                   <ul className="unorderList starWrp">
//                   {generateStars(testimonial.rating)}


//                   </ul>
//                 </div>
//                 <p>
//                 {testimonial.testimonialdescription}
//                 </p>
//                 <h3>{testimonial.testimonialname}</h3>
//                 <div className="quote_icon">
//                   <i className="fas fa-quote-left"></i>
//                 </div>
//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Testimonials;
