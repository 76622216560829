import React from "react";

const Enroll = () => {
  return (
    <div className="choice-wrap enroll-wrap">
      <div className="container">
        <div className="title">
          <h1>Call To Enroll</h1>
        </div>
        <p>
          Brilliant Academy is one of the best institutes in Marathahalli
          providing training for a variety of programming languages, software
          technologies, and foreign language courses. The training is handled by
          industry experts who are proficient in their field and have a maximum
          working experience of more than 10 years. Brilliant Academy also
          provides placement support for students which students can utilize to
          get placed in the top-tier companies. Enroll in Brilliant Academy and
          realize your dream of working for top organizations.
        </p>
        <div className="phonewrp">
          <img src="assets/images/phone_icon.png" alt="" />
          <a href="#">9900213083</a>
        </div>
      </div>
    </div>
  );
};

export default Enroll;
