import React from "react";
import Logo from '../../Dashboard/Images/logo.png'

const Footer = () => {
  return (
    <>
      <div className="footer-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="footer_logo">
                <img
                  alt=""
                  className="footer-default"
                  src={Logo}
                />
              </div>
              <p>
                Brilliant Academy is one of the best institutes in Marathahalli
                providing training for a variety of programming languages,
                software technologies, and foreign language courses. The
                training is handled by industry experts who are proficient in
                their field and have a maximum working experience of more than
                10 years. Brilliant Academy also provides placement support for
                students which students can utilize to get placed in the
                top-tier companies. Enroll in Brilliant Academy and realize your
                dream of working for top organizations.
              </p>
            </div>
            <div className="col-lg-2 col-md-3">
              <h3>Quick links</h3>
              <ul className="footer-links">
                <li>
                  {" "}
                  <a href="/">Home</a>
                </li>
                <li>
                  {" "}
                  <a href="aboutus">About</a>
                </li>
                <li>
                  {" "}
                  <a href="classblog">classes</a>
                </li>
                <li>
                  {" "}
                  <a href="teacherblog">Teachers</a>
                </li>
                <li>
                  {" "}
                  <a href="testimonialdetails">Testimonials</a>
                </li>
                <li>
                  {" "}
                  <a href="contact">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4">
              <h3>Business Hours</h3>
              <ul className="unorderList hourswrp">
                <li>
                  Monday <span>09:00 AM - 08:00 PM</span>
                </li>
                <li>
                  Tuesday <span>09:00 AM - 08:00 PM</span>
                </li>
                <li>
                  Wednesday <span>09:00 AM - 08:00 PM</span>
                </li>
                <li>
                  Thursday <span>09:00 AM - 08:00 PM</span>
                </li>
                <li>
                  Friday <span>09:00 AM - 08:00 PM</span>
                </li>
                <li>
                  Saturday <span>09:00 AM - 08:00 PM</span>
                </li>
                <li>
                  Sunday <span>09:00 AM - 08:00 PM</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="footer_info">
                <h3>Get in Touch</h3>
                <ul className="footer-adress">
                  <li className="footer_address">
                    {" "}
                    <i className="fas fa-map-marker-alt"></i>{" "}
                    <span>
                      {" "}
                    <a href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA">
                    {" "}
                     <span style={{fontWeight:"bold"}}>Branch : 1</span> - M.T.K Reddy Building, #1, 2<sup>nd</sup> Floor, Above
                      Sangeetha Mobiles AND SBI Bank, opp. KLM Fashion Mall, Marathahalli Bridge Signal
                      Bengaluru, Karnataka 560037 India
                      {" "}
                      </a>
                    </span>{" "}
                  </li>
                  <li className="footer_address">
                    {" "}
                    <i className="fas fa-map-marker-alt"></i>{" "}
                    <span>
                      {" "}
                    <a href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA">
                    {" "}
                     <span style={{fontWeight:"bold"}}>Branch : 2</span> - Beside Kala Mandir, Marathahalli Bridge Signal
                      Bengaluru, Karnataka 560037 India
                      {" "}
                      </a>
                    </span>{" "}
                  </li>
                  <li className="footer_email">
                    {" "}
                    <i className="fas fa-envelope" aria-hidden="true"></i>{" "}
                    <span>
                      <a href="mailto:info@example.com">
                        {" "}
                        brilliantinfotechnic@gmail.com{" "}
                      </a>
                    </span>{" "}
                  </li>
                  <li className="footer_phone">
                    {" "}
                    <i className="fas fa-phone-alt"></i>{" "}
                    <span>
                      <a href="tel:9900213083"> 9900213083 /</a>
                      <a href="tel:9845555716"> 9845555716</a>
                    </span>{" "}
                  </li>
                  <li className="footer_phone">
                    {" "}
                    <i className="fas fa-fax"></i>{" "}
                    <span>
                      <a href="tel:080-40942836"> 080-40942836</a>
                    </span>{" "}
                  </li>
                </ul>
                <div className="social-icons footer_icon">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright start */}
      <div className="footer-bottom text-center">
        <div className="container">
          <div className="copyright-text">
            Copyright © Brilliant Academy 2024. All Rights Reserved
          </div>
        </div>
      </div>
      {/* Copyright end */}
    </>
  );
};

export default Footer;
