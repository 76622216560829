import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ROOT_URL from "../../Utilities/apiconfig";

const Teacherblog = () => {
  const [trainers, setTrainers] = useState([]);

  useEffect(() => {
    async function fetchTrainers() {
      try {
        const response = await fetch(`${ROOT_URL}/alltrainers`);
        if (!response.ok) {
          throw new Error("Failed to fetch trainers");
        }
        const trainersData = await response.json();
        setTrainers(trainersData.data);
      } catch (error) {
        console.error("Error fetching trainers:", error);
      }
    }

    fetchTrainers();
  }, []);

  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Trainers</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container">
          <div className="innerteacher-wrap">
            <div className="row">
              {trainers.map((trainer) => (
                <div className="col-lg-3 col-md-6 ">
                  <Link to={`/teacherdetails/${trainer.id}`} className="trainer-link">
                  <div className="single-teachers">
                    <div className="teacherImg">
                      {" "}
                      <img
                        src={trainer.trainerimage}
                        alt={trainer.trainername}
                        style={{ border: "1px solid gray", objectFit: "cover" }}
                      />
                      <ul className="social-icons list-inline">
                        <li className="social-facebook">
                          {" "}
                          <a href="#">
                            <i
                              className="fab fa-facebook-f"
                              aria-hidden="true"
                            ></i>
                          </a>{" "}
                        </li>
                        <li className="social-twitter">
                          {" "}
                          <a href="#">
                            <i
                              className="fab fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>{" "}
                        </li>
                        <li className="social-linkedin">
                          {" "}
                          <a href="#">
                            <i
                              className="fab fa-linkedin-in"
                              aria-hidden="true"
                            ></i>
                          </a>{" "}
                        </li>
                        <li className="social-googleplus">
                          {" "}
                          <a href="#">
                            <i
                              className="fab fa-instagram"
                              aria-hidden="true"
                            ></i>
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="teachers-content">
                      <h3>{trainer.trainername}</h3>
                      <div className="designation">{trainer.trainercourse}</div>
                    </div>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Teacherblog;
