import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const Addslider = () => {
  const [formData, setFormData] = useState({
    maintitle: "",
    subtitle: "",
    sliderimage: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${ROOT_URL}/sliderpost`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.message);

        setFormData({
        maintitle: "",
          subtitle: "",
          sliderimage: null,
        });
         // Clear the image preview
      setImage(null);
      setImageName("");
        // Display toast message for 5 seconds
        toast.success(response.data.message, { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error logging in :", error);
        // Display toast message for login failure
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
  };

  const [sliderimage, setImage] = useState(null);
  const [imageName, setImageName] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, sliderimage: file }); // Set the file in form data

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
      setImageName(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImageName("");
    setFormData({ ...formData, sliderimage: null });
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Add Courses</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Main Title</label>
          <input
            type="text"
            className="form-control"
            placeholder="Main Title"
            id="maintitle"
            name="maintitle"
            value={formData.maintitle}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Sub Title</label>
          <input
            type="text"
            className="form-control"
            placeholder="Sub Title"
            id="subtitle"
            name="subtitle"
            value={formData.subtitle}
            onChange={handleChange}
          />
        </div>
        <div
          className="border border-gray rounded p-4 mb-3"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
            <h6 className="text-primary mb-3">Add Image</h6>
            <div className="input-group mb-3">
              <label htmlFor="inputGroupFile02" className="form-control">
                Choose File
                <input
                  type="file"
                  className="d-none"
                  id="inputGroupFile02"
                  name="sliderimage"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {sliderimage && (
            <div
              className="mt-3"
              style={{
                position: "relative",
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={sliderimage}
                  alt="Uploaded"
                  style={{ maxWidth: "20%" }}
                />
                <button
                  onClick={handleCancelImage}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              {imageName && (
                <div style={{ marginLeft: "10px" }}>
                  <p>{imageName}</p>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Addslider;
