import React from "react";
import ROOT_URL from "../../Utilities/apiconfig";

const Teacher = () => {
  return (
    <section className="teachers-area-three teacher-wrap pt-100 pb-70  ">
      <div className="container">
        <div className="title center_title">
          <h1>Our Trainers</h1>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="single-teachers">
              <div className="teacherImg">
                {" "}
                <img src="assets/images/user-male.png" alt="Image" style={{ border: '1px solid gray',objectFit: "cover" }}/>
                <ul className="social-icons list-inline">
                  <li className="social-facebook">
                    {" "}
                    <a href="#">
                      <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-twitter">
                    {" "}
                    <a href="#">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-linkedin">
                    {" "}
                    <a href="#">
                      <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-googleplus">
                    {" "}
                    <a href="#">
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                </ul>
              </div>
              <div className="teachers-content">
                <h3>Raghava</h3>
                <div className="designation">Java trainer</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="single-teachers">
              <div className="teacherImg">
                {" "}
                <img src="assets/images/user-male.png" alt="Image" style={{ border: '1px solid gray', objectFit: "cover" }}/>
                <ul className="social-icons list-inline">
                  <li className="social-facebook">
                    {" "}
                    <a href="#">
                      <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-twitter">
                    {" "}
                    <a href="#">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-linkedin">
                    {" "}
                    <a href="#">
                      <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-googleplus">
                    {" "}
                    <a href="#">
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                </ul>
              </div>
              <div className="teachers-content">
                <h3>Subramanyam</h3>
                <div className="designation">SAP Trainer</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="single-teachers">
              <div className="teacherImg">
                {" "}
                <img src="assets/images/user-male.png" alt="Image" style={{ border: '1px solid gray',  objectFit: "cover" }}/>
                <ul className="social-icons list-inline">
                  <li className="social-facebook">
                    {" "}
                    <a href="#">
                      <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-twitter">
                    {" "}
                    <a href="#">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-linkedin">
                    {" "}
                    <a href="#">
                      <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-googleplus">
                    {" "}
                    <a href="#">
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                </ul>
              </div>
              <div className="teachers-content">
                <h3>Kesava</h3>
                <div className="designation">Dot Net Trainer</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="single-teachers">
              <div className="teacherImg">
                {" "}
                <img src="assets/images/user-male.png" alt="Image"  style={{ border: '1px solid gray', objectFit: "cover" }} />
                <ul className="social-icons list-inline">
                  <li className="social-facebook">
                    {" "}
                    <a href="#">
                      <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-twitter">
                    {" "}
                    <a href="#">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-linkedin">
                    {" "}
                    <a href="#">
                      <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="social-googleplus">
                    {" "}
                    <a href="#">
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                </ul>
              </div>
              <div className="teachers-content">
                <h3>Ravindra</h3>
                <div className="designation">C, C++ Trainer</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Teacher;
