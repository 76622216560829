import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ROOT_URL from "../../Utilities/apiconfig";

const Classesblog = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(`${ROOT_URL}/getcourses`)
      .then((res) => {
        console.log("Courses API Response:", res);
        if (res.data && res.data.length > 0) {
          setCourses(res.data);
        } else {
          console.error(
            "Error retrieving courses: No courses found in the response"
          );
        }
      })
      .catch((error) => {
        console.error("Error retrieving courses:", error.message);
      });
  };

  const handleContainerClick = (id) => {
    console.log("Container clicked for course with ID:", id);
    navigate(`/classdetails/${id}`);
  };

  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Courses</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container">
          <div className="class-wrap">
            <ul className="row unorderList">
              {courses.map((course) => (
                <li
                  key={course.id}
                  className="col-lg-4 col-md-6"
                  onClick={() => handleContainerClick(course.id)}
                  style={{ cursor: "pointer" }} // Change cursor to indicate clickable
                >
                  <div className="class_box">
                    <div className="class_Img">
                      <div
                        className="image-wrapper"
                        style={{
                          backgroundImage: `url(${course.courseimage})`,
                          backgroundSize: "cover",
                          width: "100%",
                          height: "200px",
                        }}
                      >
                        {!course.courseimage && (
                          <img
                            // src="assets/images/placeholder.png"
                            alt=""
                            style={{ display: "none" }}
                          />
                        )}
                      </div>
                      <div className="time_box">
                        <span>{course.classtime}</span>
                      </div>
                    </div>

                    <div className="path_box">
                      <h3>
                        <a href="#">{course.coursename}</a>
                      </h3>
                      <p  className="course-description">{course.coursedescription}</p>
                      <div className="students_box">
                        <div className="students">
                          Duration: {course.duration}
                        </div>
                        <div className="stud_fee">Fee: {course.fees}</div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Classesblog;