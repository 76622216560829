import React from "react";
import Gallery from "./Gallery/Gallery";
import Testimonials from "./Testimonials/Testimonials";
import Enroll from "./Enroll/Enroll";
import Teacher from "./Teacher/Teacher";
import Programs from "./Programs/Programs";
import Newsletter from "./Newsletter/Newsletter";
import Footer from "./Footer/Footer";
import Slider from "./Slider/Slider";
import Classes from "./Classes/Classes";
import Header from "./Header/Header";
import About from "./About/About";
import School from "./School/school";
import Choice from "./Choice/Choice";
import Video from "./Video/Video";
import './Styles/all.css';
import './Styles/style.css';
import './Styles/owl.carousel.css';

const Blog = () => {
  return (
    <>
      <Header />
      <Slider />
      <School />
      <Classes />
      <About />  
      <Choice/>
      <Video/>
      <Gallery />
      <Testimonials />
      <Enroll />
      <Programs />
      <Footer />
    </>
  );
};

export default Blog;
