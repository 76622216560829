import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const Updatetrainers = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { id } = useParams();
  const [formData, setFormData] = useState({
    trainername: "",
    trainerimage: null,
    trainercourse: "",
    description: "",
    email: "",
    phonenumber: "",
    address: "",
    teaching: "",
    communication: "",
    support: "",
    imagename: null,
  });

  useEffect(() => {
    fetchtrainers();
  }, [id]);

  const fetchtrainers = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/byidtrainersget`, {
        headers: { trainerid: id },
      });
      console.log("trainers data:", response.data);
      const trainersData = response.data;

      // Populate formData with fetched data
      setFormData((prevState) => ({
        ...prevState,
        trainername: trainersData.data.trainername,
        trainerimage: trainersData.data.trainerimage,
        trainercourse: trainersData.data.trainercourse,
        description: trainersData.data.description,
        email: trainersData.data.email,
        phonenumber: trainersData.data.phonenumber,
        address: trainersData.data.address,
        teaching: trainersData.data.teaching,
        communication: trainersData.data.communication,
        support: trainersData.data.support,
        imagename: trainersData.data.imagename,
      }));
      setImageUrl(trainersData.data.trainerimage);
      console.log(formData);
    } catch (error) {
      console.error("Error fetching trainers:", error);
    }
  };

  ///////updatebyId courses/////

  const updateTrainer = async () => {
    try {
      const formDataForUpdate = new FormData();
      formDataForUpdate.append("trainername", formData.trainername);
      formDataForUpdate.append("trainercourse", formData.trainercourse);
      formDataForUpdate.append("description", formData.description);
      formDataForUpdate.append("email", formData.email);
      formDataForUpdate.append("phonenumber", formData.phonenumber);
      formDataForUpdate.append("address", formData.address);
      formDataForUpdate.append("teaching", formData.teaching);
      formDataForUpdate.append("communication", formData.communication);
      formDataForUpdate.append("support", formData.support);
      formDataForUpdate.append("trainerimage", image);

      await axios.put(`${ROOT_URL}/idbyupdatetrainers`, formDataForUpdate, {
        headers: {
          trainerid: id,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Trainer updated successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error("Error updating trainer:", error);
      toast.error("Failed to update trainer. Please try again later.");
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);

      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImageUrl("");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Update Trainer</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Trainer Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Trainer Name"
            id="trainername"
            name="trainername"
            value={formData.trainername}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Trainer Course</label>
          <input
            type="text"
            className="form-control"
            placeholder="Trainer Course"
            id="trainercourse"
            name="trainercourse"
            value={formData.trainercourse}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Description</label>
          <input
            type="text"
            className="form-control"
            placeholder="Description"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Email</label>
          <input
            type="text"
            className="form-control"
            placeholder="Email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Phone Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="Phone Number"
            id="Fee"
            name="phonenumber"
            value={formData.phonenumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Address</label>
          <input
            type="text"
            className="form-control"
            placeholder="Address"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="teachingInput">Teaching</label>
          <input
            type="text"
            className="form-control"
            placeholder="Teaching"
            id="teaching"
            name="teaching"
            value={formData.teaching}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="addressInput">Communication</label>
          <input
            type="text"
            className="form-control"
            placeholder="Communication"
            id="communication"
            name="communication"
            value={formData.communication}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="addressInput">Support</label>
          <input
            type="text"
            className="form-control"
            placeholder="Support"
            id="support"
            name="support"
            value={formData.support}
            onChange={handleChange}
          />
        </div>
        <div
          className="border border-gray rounded p-4 mb-3"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
            <h6 className="text-primary mb-3">Add Image</h6>
            <div className="input-group mb-3">
              <label htmlFor="inputGroupFile02" className="form-control">
                Choose File
                <input
                  type="file"
                  className="d-none"
                  id="inputGroupFile02"
                  name="trainerimage"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {imageUrl && (
            <div
              className="mt-3"
              style={{
                position: "relative",
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Uploaded"
                  style={{ maxWidth: "20%" }}
                />
                <button
                  onClick={handleCancelImage}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={updateTrainer}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Updatetrainers;
