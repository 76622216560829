import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ROOT_URL from "../../Utilities/apiconfig";

const Galleryimages = () => {
  const [galleryimages, setGalleryImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [GalleryImagesPerPage] = useState(5); // Number of galleryimages per page
  const [pageNumberLimit] = useState(3); // Number of page numbers to display
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [deletingGalleryImageId, setDeletingGalleryImageId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch galleryimages data


    fetchGalleryImages();
  }, []);

  const fetchGalleryImages = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/getgallery`);
      setGalleryImages(response.data);
    } catch (error) {
      console.error("Error fetching galleryimages:", error);
    }
  };

  // Get current galleryimages
  const indexOfLastGallery = currentPage * GalleryImagesPerPage;
  const indexOfFirstGallery = indexOfLastGallery - GalleryImagesPerPage;
  const currentGallery = galleryimages.slice(indexOfFirstGallery, indexOfLastGallery);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);

    if (pageNumber > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }

    if (pageNumber < minPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleDeleteGallery = async () => {
    try {
      if (deletingGalleryImageId) {
        // If deletingGalleryImageId exists, execute delete by ID API
        const response = await axios.delete(`${ROOT_URL}/idbydeletegallery`, {
          headers: { id: deletingGalleryImageId },
        });
        // Display toast message returned from the backend
        toast.success(response.data.message);
        // After deletion, fetch the updated list of galleryimages
        const updatedgalleryimages = galleryimages.filter(
          (gallery) => gallery.id !== deletingGalleryImageId
        );
        setGalleryImages(updatedgalleryimages);
      } else {
        // If deletingGalleryImageId does not exist, execute delete all API
        const response = await axios.delete(`${ROOT_URL}/alldeletegallery`);
        // Display toast message returned from the backend
        toast.success(response.data.message);
        // After deletion, set the galleryimages state to an empty array
        setGalleryImages([]);
      }
    } catch (error) {
      if (error.response) {
        // If the backend returns an error response
        const errorMessage =
          error.response.data.message || "Error deleting gallery image";
        // Display the error message in the toast notification
        toast.error(errorMessage);
      } else {
        // If there is a network error or other unexpected error
        toast.error("Error deleting Gallery Image");
        console.error("Error deleting Gallery Image:", error);
      }
    }
    // Close the modal after deletion
    setIsDeleteModalOpen(false);
    setDeletingGalleryImageId(null);
  };

  // Rendering page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(galleryimages.length / GalleryImagesPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    if (number >= minPageNumberLimit && number <= maxPageNumberLimit) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <a href="#"
            className="page-link"
            onClick={() => paginate(number)}
          >
            {number}
          </a>
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="table-wrapper">
      <div className="table-title">
        <div className="row">
          <div className="col-sm-6 p-0 flex justify-content-lg-start justify-content-center">
            <h2 className="ml-lg-2">Manage Gallery Images</h2>
          </div>
          <div class="col-sm-6 p-0 flex justify-content-lg-end justify-content-center">
            <a href="addgalleryimages" class="btn btn-success">
              <i class="material-icons">&#xE147;</i>
              <span>Add New Gallery</span>
            </a>
            <a
              href="#"
              class="btn btn-danger"
              data-toggle="modal"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              <i class="material-icons">&#xE15C;</i>
              <span>Delete All</span>
            </a>
            <a href="" class="btn btn-primary" onClick={fetchGalleryImages}>
              <i class="material-icons">&#xE5D5;</i>
              <span>Refresh</span>
            </a>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <span className="custom-checkbox" />
                <input type="checkbox" id="selectAll" />
                <label htmlFor="selectAll"></label>
              </th>
              <th>Id</th>
              <th>Gallery Image</th>
            </tr>
          </thead>
          <tbody>
            {currentGallery.map((gallery) => (
              <tr key={gallery.id}>
                <td>
                  <span className="custom-checkbox" />
                  <input
                    type="checkbox"
                    id={`checkbox${gallery.id}`}
                    name="option[]"
                    value={gallery.id}
                  />
                  <label htmlFor={`checkbox${gallery.id}`}></label>
                </td>
                <td>{gallery.id}</td>
                <td>
                  <img
                    src={gallery.galleryimage}
                    alt={gallery.name}
                    style={{ maxWidth: "100px", height: "80px" }}
                  />
                </td>
                <td>
                  <a
                    href=""
                    className="edit"
                    onClick={() => navigate(`/home/updategalleryimages/${gallery.id}`)}
                  >
                    <i
                      className="material-icons"
                      data-toggle="tooltip"
                      title="Edit"
                    >
                      &#xE254;
                    </i>
                  </a>
                  <a
                    href="#"
                    className="delete"
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setDeletingGalleryImageId(gallery.id);
                    }}
                  >
                    <i
                      className="material-icons"
                      data-toggle="tooltip"
                      title="Delete"
                    >
                      &#xE872;
                    </i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>

      <div className="clearfix">
        <div className="hint-text">
          Showing <b>{indexOfFirstGallery + 1}</b> to{" "}
          <b>{Math.min(indexOfLastGallery, galleryimages.length)}</b> out of{" "}
          <b>{galleryimages.length}</b>
        </div>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a href="#" onClick={() => paginate(currentPage - 1)}>
              Previous
            </a>
          </li>
          {renderPageNumbers}
          <li
            className={`page-item ${
              currentPage === Math.ceil(galleryimages.length / GalleryImagesPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <a href="#" onClick={() => paginate(currentPage + 1)}>
              Next
            </a>
          </li>
        </ul>
      </div>

      <div
        className={`modal fade ${isDeleteModalOpen ? "show" : ""}`}
        id="deleteEmployeeModal"
        role="dialog"
        style={{ display: isDeleteModalOpen ? "block" : "none" }}
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Delete Gallery Images</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete this Records</p>
              <p class="text-warning">
                <small>this action Cannot be Undone,</small>
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-success"
                onClick={handleDeleteGallery}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galleryimages;
