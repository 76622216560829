import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blog from "../Blog/Blog";
import Register from "../Blog/Register/Register";
import Login from "../Blog/Login/Login";
import Aboutus from "../Blog/About/Aboutus";
import Classesblog from "../Blog/Classes/Classesblog";
import Classesdetails from "../Blog/Classes/Classesdetails";
import Teacherblog from "../Blog/Teacher/Teacherblog";
import Teacherdetails from "../Blog/Teacher/Teacherdetails";
import Contact from "../Blog/Contact/Contact";
import Testimonialsdetails from "../Blog/Testimonials/Testimonialsdetails";
import Error from "../Blog/Pages/Error";
import Home from "../Dashboard/Home/Home";
import ResetPassword from "../Blog/Pages/ResetPassword";
import Courses from "../Dashboard/Courses/Courses";
import Addcourses from "../Dashboard/Courses/Addcourses";
import Updatecourses from "../Dashboard/Courses/Updatecourses";
import Bookings from "../Dashboard/Bookings/Bookings";
import Updatebookings from "../Dashboard/Bookings/Updatebookings";
import Trainer from "../Dashboard/Trainers/Trainer";
import Addtrainers from "../Dashboard/Trainers/Addtrainers";
import Updatetrainers from "../Dashboard/Trainers/Updatetrainers";
import Contactdetails from "../Dashboard/Contacts/Contactdetails";
import Adminprotectedroute from "../Authentication/Adminprotectedroute";
import Userprotectedroute from "../Authentication/Userprotectedroute";
import Users from "../Dashboard/Users/Users";
import Updateusers from "../Dashboard/Users/Updateusers";
import Galleryimages from "../Dashboard/Gallery/Galleryimages";
import Addgalleryimages from "../Dashboard/Gallery/Addgalleryimages";
import Updategalleryimages from "../Dashboard/Gallery/Updategalleryimages";
import Sliderdetails from "../Dashboard/Slider/Sliderdetails";
import Addslider from "../Dashboard/Slider/Addslider";
import Updateslider from "../Dashboard/Slider/Updateslider";
import Usersbooking from "../Blog/Pages/Usersbooking";
import Payment from "../Payment/Payment";
import Enrollment from "../Dashboard/Enroll/Enrollment";
import Addtestimonial from "../Dashboard/Testimonials/Addtestimonial";
import Testimonialdetails from "../Dashboard/Testimonials/Testimonialdetails";
import Updatetestimonial from "../Dashboard/Testimonials/Updatetestimonial";

function Rootstock() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Blog />} />
        <Route path="/login" element={<Login />} />
        <Route path="/changepassword" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/classblog" element={<Classesblog />} />
        <Route path="/classdetails/:id" element={<Classesdetails />} />
        <Route path="/teacherblog" element={<Teacherblog />} />
        <Route path="/teacherdetails/:id" element={<Teacherdetails />} />
        <Route path="/testimonialdetails" element={<Testimonialsdetails />} />
        <Route path="*" element={<Error />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/payment" element={<Userprotectedroute element={<Payment />} />} />
        <Route path="/userbookings" element={<Userprotectedroute element={<Usersbooking />} />} />
        <Route
          path="/home"
          element={<Adminprotectedroute element={<Home />} />}
        >
          <Route
            path="courses"
            element={<Adminprotectedroute element={<Courses />} />}
          />
          <Route
            path="addcourses"
            element={<Adminprotectedroute element={<Addcourses />} />}
          />
          <Route
            path="updatecourses/:id"
            element={<Adminprotectedroute element={<Updatecourses />} />}
          />
          <Route
            path="bookings"
            element={<Adminprotectedroute element={<Bookings />} />}
          />
          <Route
            path="updatebookings/:id"
            element={<Adminprotectedroute element={<Updatebookings />} />}
          />
          <Route
            path="trainers"
            element={<Adminprotectedroute element={<Trainer />} />}
          />
          <Route
            path="addtrainers"
            element={<Adminprotectedroute element={<Addtrainers />} />}
          />
          <Route
            path="updatetrainers/:id"
            element={<Adminprotectedroute element={<Updatetrainers />} />}
          />
          <Route
            path="contacts"
            element={<Adminprotectedroute element={<Contactdetails />} />}
          />
          <Route
            path="users"
            element={<Adminprotectedroute element={<Users />} />}
          />
          <Route
            path="updateusers/:id"
            element={<Adminprotectedroute element={<Updateusers />} />}
          />
          <Route
            path="galleryimages"
            element={<Adminprotectedroute element={<Galleryimages />} />}
          />
          <Route
            path="addgalleryimages"
            element={<Adminprotectedroute element={<Addgalleryimages />} />}
          />
          <Route
            path="updategalleryimages/:id"
            element={<Adminprotectedroute element={<Updategalleryimages />} />}
          />
            <Route
            path="sliderdetails"
            element={<Adminprotectedroute element={<Sliderdetails />} />}
          />
          <Route
            path="sliderpost"
            element={<Adminprotectedroute element={<Addslider />} />}
          />
          <Route
            path="updateslider/:id"
            element={<Adminprotectedroute element={<Updateslider />} />}
          />
           <Route
            path="enrollment"
            element={<Adminprotectedroute element={<Enrollment />} />}
          />
           <Route
            path="addtestimonial"
            element={<Adminprotectedroute element={<Addtestimonial />} />}
          />
           <Route
            path="testimonialdetails"
            element={<Adminprotectedroute element={<Testimonialdetails />} />}
          />
           <Route
            path="updatetestimonial/:id"
            element={<Adminprotectedroute element={<Updatetestimonial />} />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default Rootstock;
