import React from "react";

const Video = () => {
  return (
    <div className="video-wrap  ">
      <div className="container">
        <div className="title center_title">
          <h1>Watch Our Video</h1>
        </div>
        <div className="video" style={{margin:"50px"}}>
          <div className="playbtn">
            <a data-fancybox="" href="https://www.youtube.com/watch?v=ax_lVNahhEs">
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
