import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Header from "../Header/Header";
import ROOT_URL from "../../Utilities/apiconfig";

const Classesdetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const userId = user ? user.id : null;
  const [bookings, setBookings] = useState([]);
  const [formData, setFormData] = useState({
    coursename: "",
    courseimage: "",
    coursesize: "",
    duration: "",
    classtime: "",
    fees: "",
    coursedescription: "",
  });

  ////////////////enrool form data////////////////
  const [EnrolformData, setEnrollFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    coursedetails: "",
  });

  const handleChange = (e) => {
    setEnrollFormData({ ...EnrolformData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${ROOT_URL}/enrollpost`,
        EnrolformData
      );
      console.log(response.data);
      toast.success(response.data.message);

      // Reset form fields after successful submission
      setEnrollFormData({
        firstname: "",
        lastname: "",
        email: "",
        phonenumber: "",
        coursedetails: "",
      });

      // Optionally, you can redirect the user after successful enrollment
    } catch (error) {
      console.error("Error enrolling:", error);
      toast.error("Error enrolling. Please try again later.");
    }
  };

  const handleBookNowClick = () => {
    if (!userId) {
      // User is not logged in, show toast message
      toast.error("Please login to book the schedules.");
    } else {
      // Check if the user has already booked the course
      const isCourseBooked = bookings.some(
        (booking) => booking.userId === userId && booking.courseId == id
      ); // Use loose comparison to compare courseId
    
      if (isCourseBooked) {
        // User has already booked this course, show toast message
        toast.error("You have already booked this course.");
      } else {
        // User is logged in and hasn't booked this course, navigate to payment page
        navigate(`/payment?fees=${formData.fees}&courseId=${id}`);
      }
    }
  };
  

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${ROOT_URL}/allbookings`);
        console.log("Bookings data:", response.data);
        setBookings(response.data.data); // Assuming response.data contains the bookings array
      } catch (error) {
        console.error("Error fetching bookings:", error);
        toast.error("Error fetching bookings. Please try again later.");
      }
    };
  
    const fetchCourses = async () => {
      try {
        const lowercaseID = id.toLowerCase(); // Convert UUID to lowercase
        const response = await axios.get(`${ROOT_URL}/idbygetcourses`, {
          headers: { id: lowercaseID },
        });
        console.log("API response:", response.data);
        const courseData = response.data.data;
  
        // Ensure courseData is not empty before setting form data
        if (courseData) {
          console.log("Course data:", courseData);
          setFormData(courseData);
        } else {
          console.error("No course data found for UUID:", lowercaseID);
        }
      } catch (error) {
        console.error("Error fetching course:", error);
      }
    };
  
    fetchBookings(); // Fetch bookings data
    fetchCourses(); // Fetch courses data
  
  }, [id]);
  




  return (
    <>
      <Header />

      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Payment Details</h1>
        </div>
      </div>

      <div className="innerContent-wrap">
        <div className="container">
          <div className="classDetails-wrap">
            <div className="row">
              <div className="col-lg-8">
                <div className="class_left">
                  <div className="class_Img">
                    <img src={formData.courseimage} alt="Course" />
                    <div className="time_box">
                      <span>{formData.classtime}</span>
                    </div>
                  </div>
                  <h3>{formData.coursename}</h3>
                  <button
                    type="submit"
                    className="btn mx-auto d-block booknowbutton"
                    style={{
                      backgroundColor: "#f0aa00",
                      borderRadius: "10px",
                      color: "white",
                    }}
                    onClick={handleBookNowClick}
                  >
                    <i class="far fa-calendar-alt"></i> Book Now
                  </button>

                  <p>{formData.coursedescription}</p>
                </div>
              </div>
              <div>
                <div className="tp-banner-container">
                  <div class="container mt-5 enrollcontainer">
                    <div class="row">
                      <div class="col-lg-12 ml-4">
                        <div
                          class="form-container px-5 py-5 mt-10 rounded-lg"
                          style={{ backgroundColor: "#fff8e8" }}
                        >
                          <form>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control "
                                placeholder="First Name"
                                name="firstname"
                                value={EnrolformData.firstname}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Last Name"
                                id="lastName"
                                name="lastname"
                                value={EnrolformData.lastname}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control "
                                placeholder="Email"
                                id="email"
                                name="email"
                                value={EnrolformData.email}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Phone Number"
                                id="phoneNumber"
                                name="phonenumber"
                                value={EnrolformData.phonenumber}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Course Details"
                                id="coursedetails"
                                name="coursedetails"
                                value={EnrolformData.coursedetails}
                                onChange={handleChange}
                                required
                              />
                            </div>

                            <button
                              type="submit"
                              class="btn mx-auto d-block"
                              style={{
                                backgroundColor: "#f0aa00",
                                borderRadius: "10px",
                                color: "white",
                              }}
                              onClick={handleSubmit}
                            >
                              <i class="fas fa-edit"></i> Enroll ToDay
                            </button>
                          </form>
                        </div>
                        <div className="sidebar-item">
                          <ul className="class-details">
                            <li>
                              <div className="name">
                                <i
                                  className="fas fa-building"
                                  aria-hidden="true"
                                ></i>
                                Class Size
                              </div>
                              <div className="info">{formData.coursesize}</div>
                            </li>

                            <li>
                              <div className="name">
                                <i
                                  className="fas fa-calendar"
                                  aria-hidden="true"
                                ></i>
                                Coures Duration
                              </div>
                              <div className="info">{formData.duration}</div>
                            </li>
                            <li>
                              <div className="name">
                                <i
                                  className="fas fa-clock"
                                  aria-hidden="true"
                                ></i>
                                Class Time
                              </div>
                              <div className="info">{formData.classtime}</div>
                            </li>

                            <li>
                              <div className="name">
                                <i className="fas fa-money-bill-alt"></i>Tution
                                Free
                              </div>
                              <div className="info">{formData.fees}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Classesdetails;
