import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ROOT_URL from "../../Utilities/apiconfig";

const Register = () => {
  const navigate = useNavigate();
  const[confirmPassword,setconfirmpassword] = useState ("")
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleConfirmPasswordChange = (e) => {
    setconfirmpassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

   // Check if password and confirm password match
   console.log("Form Data:", formData); // Add logging statement to check form data
   if (formData.password !== confirmPassword) {
     toast.error("Password and confirm password do not match");
     return;
   }

    axios
      .post(`${ROOT_URL}/register`, formData)
      .then((response) => {
        console.log(response.data.message);

        setFormData({
          username: "",
          email: "",
          password: "",
          firstname: "",
          lastname: "",
          phonenumber: "",
        });
        setconfirmpassword('');
        // Display toast message for 5 seconds
        toast.success(response.data.message, { autoClose: 3000 });

        // Navigate to the home page after 5 seconds
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      })
      .catch((error) => {
        console.error("Error in Registration:", error);
      // Check if the error response contains validation errors
      if (error.response.data.errors) {
        // Map through the validation errors and display them
        error.response.data.errors.forEach((err) => {
          toast.error(`${err.message}`, { autoClose: 3000 });
        });
      } else {
        // Display toast message for other errors
        toast.error(error.response.data.message, { autoClose: 3000 });
      }
      });
  };

  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Register</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container">
          <div className="login-wrap ">
            <div className="contact-info login_box">
              <div className="contact-form loginWrp registerWrp">
                <form id="contactForm" noValidate>
                  <div className="form_set">
                    <h3>Account Information</h3>
                    <div className="form-group">
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        placeholder="Username"
                        value={formData.username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <div className="passnote">
                        Note: Password must be a minimum of 8 characters
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                    </div>
                  </div>
                  <h3>Personal Information</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="firstname"
                          className="form-control"
                          placeholder="First Name"
                          value={formData.firstname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="lastname"
                          className="form-control"
                          placeholder="Last Name"
                          value={formData.lastname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Email Address"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="number"
                          name="phonenumber"
                          className="form-control"
                          placeholder="Phonenumber"
                          value={formData.phonenumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <button
                          type="submit"
                          className="default-btn btn send_btn"
                          onClick={handleSubmit}
                        >
                          Register <span></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="already_account">
                      Already have Account? <a href="/login">Login</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
};

export default Register;