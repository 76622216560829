import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ROOT_URL from "../../Utilities/apiconfig";

const Testimonialsdetails = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${ROOT_URL}/gettestimonials`);
        setTestimonials(response.data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <>
      <Header />
      <div className="innerHeading-wrap">
        <div className="container">
          <h1>Testimonials</h1>
        </div>
      </div>
      <div className="innerContent-wrap">
        <div className="container">
          <div className="testimonials-wrap">
            <ul className="row unorderList">
              {testimonials.map((testimonial, index) => (
                <li key={index} className="col-lg-6">
                  <div className="testimonials_sec">
                    <div className="client_box">
                      <div className="clientImg">
                        <img alt="" src={testimonial.testimonialimage} />
                      </div>
                      <ul className="unorderList starWrp">
                        {Array.from({ length: testimonial.rating }, (_, i) => (
                          <li key={i}>
                            <i className="fas fa-star"></i>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <p>{testimonial.testimonialdescription}</p>
                    <h3>{testimonial.testimonialname}</h3>
                    <div className="quote_icon">
                      <i className="fas fa-quote-left"></i>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Testimonialsdetails;
