import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ROOT_URL from "../../Utilities/apiconfig";

const Classes = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(`${ROOT_URL}/getcourses`)
      .then((res) => {
        console.log("Courses API Response:", res);
        if (res.data && res.data.length > 0) {
          setCourses(res.data);
        } else {
          console.error(
            "Error retrieving courses: No courses found in the response"
          );
        }
      })
      .catch((error) => {
        console.error("Error retrieving courses:", error.message);
      });
  };

  // Configuration for react-slick carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at a time

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="class-wrap" style ={{marginTop: "50px"}}>
      <div className="container">
        <div className="title">
          <h1>Our Popular Courses</h1>
        </div>
        <ul>
          <li className="item">
            {courses.length > 0 && (
              <Slider {...settings}>
                {courses.map((course, index) => (
                  <div key={course.id} className="class_box">
                    <div className="class_Img">
                      <div
                        className="image-wrapper"
                        style={{
                          backgroundImage: `url(${course.courseimage})`,
                          backgroundSize: "cover",
                          width: "100%",
                          height: "200px",
                        }}
                      >
                        {!course.courseimage && (
                          <img
                            // src="assets/images/placeholder.png"
                            alt=""
                            style={{ display: "none" }}
                          />
                        )}
                      </div>
                      <div className="time_box">
                        <span>{course.classtime}</span>
                      </div>
                    </div>
                    <div className="path_box">
                      <h3>
                        <a href="#">{course.coursename}</a>
                      </h3>
                      <p className="course-description">
                        {course.coursedescription}
                      </p>
                      <div className="students_box">
                        <div className="students">
                          Duration: {course.duration}
                        </div>
                        <div className="stud_fee">Fee: {course.fees}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Classes;
