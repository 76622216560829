import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ROOT_URL from "../../Utilities/apiconfig";

const Updatebookings = () => {
  const { id } = useParams();

  // Individual state variables for each form field
  const [amount, setAmount] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {
    fetchBookings();
  }, [id]);

  const fetchBookings = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/getidbookings`, {
        headers: { id: id },
      });
      const BookingsData = response.data;

      // Update state variables with fetched data
      setAmount(BookingsData.data.amount);
      setReferenceNo(BookingsData.data.referenceNo);
      setPaymentStatus(BookingsData.data.paymentstatus);
    } catch (error) {
      console.error("Error fetching Bookings:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `${ROOT_URL}/idbyupdatebookings`,

        {
          amount: amount,
          referenceNo: referenceNo,
          paymentstatus: paymentStatus,
        },
        {
          headers: {
            userid: "1",
            courseid: "3",
            id: id,
          },
        }
      );

      toast.success(response.data.message);
    } catch (error) {
      console.error("Error updating booking:", error);
      toast.error("Failed to update booking");
    }
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Update Bookings</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="amount">Amount</label>
          <input
            type="text"
            className="form-control"
            id="amount"
            name="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="referenceNo">Reference Number</label>
          <input
            type="text"
            className="form-control"
            id="referenceNo"
            name="referenceNo"
            value={referenceNo}
            onChange={(e) => setReferenceNo(e.target.value)}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="paymentStatus">Payment Status</label>
          <select
            className="form-control"
            id="paymentStatus"
            name="paymentStatus"
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e.target.value)}
          >
            <option value="approved">approved</option>
            <option value="rejected">rejected</option>
            <option value="transit">transit</option>
          </select>
        </div>
        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Updatebookings;
