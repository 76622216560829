import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ROOT_URL from "../../Utilities/apiconfig";

const Sliderdetails = () => {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage] = useState(5); // Number of courses per page
  const [pageNumberLimit] = useState(3); // Number of page numbers to display
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [deletingCourseId, setDeletingCourseId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch courses data


    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/getslider`);
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  // Get current courses
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentSlider = courses.slice(indexOfFirstCourse, indexOfLastCourse);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);

    if (pageNumber > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }

    if (pageNumber < minPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleDeleteCourse = async () => {
    try {
      if (deletingCourseId) {
        // If deletingCourseId exists, execute delete by ID API
        const response = await axios.delete(`${ROOT_URL}/idbydeleteslider`, {
          headers: { id: deletingCourseId },
        });
        // Display toast message returned from the backend
        toast.success(response.data.message);
        // After deletion, fetch the updated list of courses
        const updatedCourses = courses.filter(
          (course) => course.id !== deletingCourseId
        );
        setCourses(updatedCourses);
      } else {
        // If deletingCourseId does not exist, execute delete all API
        const response = await axios.delete(`${ROOT_URL}/allsliderdelete`);
        // Display toast message returned from the backend
        toast.success(response.data.message);
        // After deletion, set the courses state to an empty array
        setCourses([]);
      }
    } catch (error) {
      if (error.response) {
        // If the backend returns an error response
        const errorMessage =
          error.response.data.message || "Error deleting course";
        // Display the error message in the toast notification
        toast.error(errorMessage);
      } else {
        // If there is a network error or other unexpected error
        toast.error("Error deleting course");
        console.error("Error deleting course:", error);
      }
    }
    // Close the modal after deletion
    setIsDeleteModalOpen(false);
    setDeletingCourseId(null);
  };

  // Rendering page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(courses.length / coursesPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    if (number >= minPageNumberLimit && number <= maxPageNumberLimit) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <a
            className="page-link"
            onClick={() => paginate(number)}
          >
            {number}
          </a>
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="table-wrapper">
      <div className="table-title">
        <div className="row">
          <div className="col-sm-6 p-0 flex justify-content-lg-start justify-content-center">
            <h2 className="ml-lg-2">Manage Slider</h2>
          </div>
          <div class="col-sm-6 p-0 flex justify-content-lg-end justify-content-center">
            <a href="sliderpost" class="btn btn-success">
              <i class="material-icons">&#xE147;</i>
              <span>Add New Slider</span>
            </a>
            <a
              href="#"
              class="btn btn-danger"
              data-toggle="modal"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              <i class="material-icons">&#xE15C;</i>
              <span>Delete All</span>
            </a>
            <a href="" class="btn btn-primary" onClick={fetchCourses}>
              <i class="material-icons">&#xE5D5;</i>
              <span>Refresh</span>
            </a>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <span className="custom-checkbox" />
                <input type="checkbox" id="selectAll" />
                <label htmlFor="selectAll"></label>
              </th>
              <th>ID</th>
              <th>Main Title</th>
              <th>Sub title</th>
              <th>Slider Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentSlider.map((slider) => (
              <tr key={slider.id}>
                
                <td>
                  <span className="custom-checkbox" />
                  <input
                    type="checkbox"
                    id={`checkbox${slider.id}`}
                    name="option[]"
                    value={slider.id}
                  />
                  <label htmlFor={`checkbox${slider.id}`}></label>
                </td>
                <td>{slider.id}</td>
                <td>{slider.maintitle}</td>
                <td>{slider.subtitle}</td>
                <td>
                  <img
                    src={slider.sliderimage}
                    alt={slider.maintitle}
                    style={{ maxWidth: "100px", height: "80px" }}
                  />
                </td>
            
                <td>
                  <a
                    href=""
                    className="edit"
                    onClick={() => navigate(`/home/updateslider/${slider.id}`)}
                  >
                    <i
                      className="material-icons"
                      data-toggle="tooltip"
                      title="Edit"
                    >
                      &#xE254;
                    </i>
                  </a>
                  <a
                    href="#"
                    className="delete"
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setDeletingCourseId(slider.id);
                    }}
                  >
                    <i
                      className="material-icons"
                      data-toggle="tooltip"
                      title="Delete"
                    >
                      &#xE872;
                    </i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>

      <div className="clearfix">
        <div className="hint-text">
          Showing <b>{indexOfFirstCourse + 1}</b> to{" "}
          <b>{Math.min(indexOfLastCourse, courses.length)}</b> out of{" "}
          <b>{courses.length}</b>
        </div>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a href="#" onClick={() => paginate(currentPage - 1)}>
              Previous
            </a>
          </li>
          {renderPageNumbers}
          <li
            className={`page-item ${
              currentPage === Math.ceil(courses.length / coursesPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <a href="#" onClick={() => paginate(currentPage + 1)}>
              Next
            </a>
          </li>
        </ul>
      </div>

      <div
        className={`modal fade ${isDeleteModalOpen ? "show" : ""}`}
        id="deleteEmployeeModal"
        role="dialog"
        style={{ display: isDeleteModalOpen ? "block" : "none" }}
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Delete Sliders</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete this Records</p>
              <p class="text-warning">
                <small>this action Cannot be Undone,</small>
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-success"
                onClick={handleDeleteCourse}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sliderdetails;
