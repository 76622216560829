import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ROOT_URL from "../../Utilities/apiconfig";

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingsPerPage] = useState(5); // Number of bookings per page
  const [pageNumberLimit] = useState(3); // Number of page numbers to display
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [deletingBookingId, setdeletingBookingId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch bookings data

    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/allbookings`);
      const responseData = response.data;

      // Check if any bookings are found
      if (responseData.isError || !responseData.data) {
        // Handle error or empty response
        console.error("Error fetching bookings:", responseData.message);
        return;
      }

      // Extract bookings from the response data
      const bookings = responseData.data;

      // Set the bookings state with the extracted bookings
      setBookings(bookings);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  // Get current bookings
  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = bookings.slice(
    indexOfFirstBooking,
    indexOfLastBooking
  );

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);

    if (pageNumber > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }

    if (pageNumber < minPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleDeleteBooking = async () => {
    try {
      if (deletingBookingId) {
        // If deletingBookingId exists, execute delete by ID API
        const response = await axios.delete(`${ROOT_URL}/idbydeletebookings`, {
          headers: { bookingid: deletingBookingId },
        });
        // Display toast message returned from the backend
        toast.success(response.data.message);
        // After deletion, fetch the updated list of bookings
        const updatedBookings = bookings.filter(
          (trainer) => trainer.id !== deletingBookingId
        );
        setBookings(updatedBookings);
      } else {
        // If deletingBookingId does not exist, execute delete all API
        const response = await axios.delete(`${ROOT_URL}/bookingsdeleteall`);
        // Display toast message returned from the backend
        toast.success(response.data.message);
        // After deletion, set the bookings state to an empty array
        setBookings([]);
      }
    } catch (error) {
      if (error.response) {
        // If the backend returns an error response
        const errorMessage =
          error.response.data.message || "Error deleting trainer";
        // Display the error message in the toast notification
        toast.error(errorMessage);
      } else {
        // If the backend returns an error response
        const errorMessage =
          error.response.data.message || "Error deleting trainer";
        // Display the error message in the toast notification
        toast.error(errorMessage);
      }
    }
    // Close the modal after deletion
    setIsDeleteModalOpen(false);
    setdeletingBookingId(null);
  };

  // Rendering page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(bookings.length / bookingsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    if (number >= minPageNumberLimit && number <= maxPageNumberLimit) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <a href="#" className="page-link" onClick={() => paginate(number)}>
            {number}
          </a>
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="table-wrapper">
      <div className="table-title">
        <div className="row">
          <div className="col-sm-6 p-0 flex justify-content-lg-start justify-content-center">
            <h2 className="ml-lg-2">Manage Bookings</h2>
          </div>
          <div class="col-sm-6 p-0 flex justify-content-lg-end justify-content-center">
            <a
              href="#"
              class="btn btn-danger"
              data-toggle="modal"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              <i class="material-icons">&#xE15C;</i>
              <span>Delete All</span>
            </a>
            <a href="" class="btn btn-primary" onClick={fetchBookings}>
              <i class="material-icons">&#xE5D5;</i>
              <span>Refresh</span>
            </a>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <span className="custom-checkbox" />
                <input type="checkbox" id="selectAll" />
                <label htmlFor="selectAll"></label>
              </th>
              <th>User Id</th>
              <th>Course Id</th>
              <th>Amount</th>
              <th>Reference No</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentBookings.map((booking) => (
              <tr key={booking.id}>
                <td>
                  <span className="custom-checkbox" />
                  <input
                    type="checkbox"
                    id={`checkbox${booking.id}`}
                    name="option[]"
                    value={booking.id}
                  />
                  <label htmlFor={`checkbox${booking.id}`}></label>
                </td>
                <td>{booking.userId}</td>
                <td>{booking.courseId}</td>
                <td>{booking.amount}</td>
                <td>{booking.referenceNo}</td>
                <td>{booking.paymentstatus}</td>
                <td>
                  <a
                    href=""
                    className="edit"
                    onClick={() =>
                      navigate(`/home/updatebookings/${booking.id}`)
                    }
                  >
                    <i
                      className="material-icons"
                      data-toggle="tooltip"
                      title="Edit"
                    >
                      &#xE254;
                    </i>
                  </a>
                  <a
                    href="#"
                    className="delete"
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setdeletingBookingId(booking.id);
                    }}
                  >
                    <i
                      className="material-icons"
                      data-toggle="tooltip"
                      title="Delete"
                    >
                      &#xE872;
                    </i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>

      <div className="clearfix">
        <div className="hint-text">
          Showing <b>{indexOfFirstBooking + 1}</b> to{" "}
          <b>{Math.min(indexOfLastBooking, bookings.length)}</b> out of{" "}
          <b>{bookings.length}</b>
        </div>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a href="#" onClick={() => paginate(currentPage - 1)}>
              Previous
            </a>
          </li>
          {renderPageNumbers}
          <li
            className={`page-item ${
              currentPage === Math.ceil(bookings.length / bookingsPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <a href="#" onClick={() => paginate(currentPage + 1)}>
              Next
            </a>
          </li>
        </ul>
      </div>

      <div
        className={`modal fade ${isDeleteModalOpen ? "show" : ""}`}
        id="deleteEmployeeModal"
        role="dialog"
        style={{ display: isDeleteModalOpen ? "block" : "none" }}
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Delete Bookings</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete this Records</p>
              <p class="text-warning">
                <small>this action Cannot be Undone,</small>
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-success"
                onClick={handleDeleteBooking}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
