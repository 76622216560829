import React, { useEffect, useState } from "react";
import "./slider.css";
import axios from "axios"; // Assuming you're using Axios for HTTP requests

const Slider = () => {
  const [sliderData, setSliderData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // Fetch data from backend API
    const fetchData = async () => {
      try {
        const response = await axios.get("https://brilliant.goldenkarat.in/api/getslider");
        setSliderData(response.data); // Assuming the response is an array of slider items
      } catch (error) {
        console.error("Error fetching slider data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to handle next slide
  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === sliderData.length - 1 ? 0 : prevIndex + 1));
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? sliderData.length - 1 : prevIndex - 1));
  };

  // Interval duration for auto sliding (in milliseconds)
  const intervalDuration = 2000; // Change this value to adjust the speed

  // Automatically change slide after a certain interval
  useEffect(() => {
    const interval = setInterval(nextSlide, intervalDuration);
    return () => clearInterval(interval);
  }, [activeIndex]); // Re-run effect when activeIndex changes

  return (
    <div id="demo" className="carousel slide" data-ride="carousel">
      <ul className="carousel-indicators">
        {sliderData.map((item, index) => (
          <li
            key={index}
            data-target="#demo"
            data-slide-to={index}
            className={index === activeIndex ? "active" : ""}
          ></li>
        ))}
      </ul>
      <div className="carousel-inner">
        {sliderData.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${index === activeIndex ? "active" : ""}`}
          >
            <div className="overlay"></div>
            <img
              src={item.sliderimage}
              alt={item.alt}
              width="1100"
              height="500"
              style={{ objectFit: "fill" }}
            />
            <div className="carousel-caption">
              <h3>{item.maintitle}</h3>
              <p>{item.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
      <a
        className="carousel-control-prev"
        href="#demo"
        data-slide="prev"
        onClick={prevSlide}
      >
        <span className="carousel-control-prev-icon"></span>
      </a>
      <a
        className="carousel-control-next"
        href="#demo"
        data-slide="next"
        onClick={nextSlide}
      >
        <span className="carousel-control-next-icon"></span>
      </a>
    </div>
  );
};

export default Slider;
