import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const AddCourses = () => {
  const [formData, setFormData] = useState({
    coursename: "",
    coursesize: "",
    duration: "",
    classtime: "",
    fees: "",
    coursedescription: "",
    courseimage: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${ROOT_URL}/coursespost`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.message);

        setFormData({
          coursename: "",
          coursesize: "",
          duration: "",
          classtime: "",
          fees: "",
          coursedescription: "",
          courseimage: null,
        });
        // Display toast message for 5 seconds
        toast.success(response.data.message, { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error adding in courses:", error);
        // Display toast message for login failure
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
  };

  const [courseimage, setImage] = useState(null);
  const [imageName, setImageName] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, courseimage: file }); // Set the file in form data

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
      setImageName(file.name); // Store the file name
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImageName("");
    setFormData({ ...formData, courseimage: null });
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Add Courses</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Course Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Course Name"
            id="courseName"
            name="coursename"
            value={formData.coursename}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Course Size</label>
          <input
            type="text"
            className="form-control"
            placeholder="Course Size"
            id="courseSize"
            name="coursesize"
            value={formData.coursesize}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Duration</label>
          <input
            type="text"
            className="form-control"
            placeholder="Duration"
            id="Duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Class Time</label>
          <input
            type="text"
            className="form-control"
            placeholder="Class Time"
            id="ClassTime"
            name="classtime"
            value={formData.classtime}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Fee</label>
          <input
            type="text"
            className="form-control"
            placeholder="Fee"
            id="Fee"
            name="fees"
            value={formData.fees}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Courses Description</label>
          <input
            type="text"
            className="form-control"
            placeholder="Courses Description"
            id="coursedescription"
            name="coursedescription"
            value={formData.coursedescription}
            onChange={handleChange}
          />
        </div>
        <div
          className="border border-gray rounded p-4 mb-3"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
            <h6 className="text-primary mb-3">Add Image</h6>
            <div className="input-group mb-3">
              <label htmlFor="inputGroupFile02" className="form-control">
                Choose File
                <input
                  type="file"
                  className="d-none"
                  id="inputGroupFile02"
                  name="courseimage"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {courseimage && (
            <div
              className="mt-3"
              style={{
                position: "relative",
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={courseimage}
                  alt="Uploaded"
                  style={{ maxWidth: "20%" }}
                />
                <button
                  onClick={handleCancelImage}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              {imageName && (
                <div style={{ marginLeft: "10px" }}>
                  <p>{imageName}</p>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddCourses;
