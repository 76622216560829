import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import ROOT_URL from "../../Utilities/apiconfig";

const Updatecourses = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { id } = useParams();
  const [formData, setFormData] = useState({
    coursename: "",
    courseimage: null,
    coursesize: "",
    duration: "",
    classtime: "",
    fees: "",
    coursedescription: "",
    imagename: null,
  });

  useEffect(() => {
    fetchcourses();
  }, [id]);

  const fetchcourses = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/idbygetcourses`, {
        headers: { id: id },
      });
      console.log("courses data:", response.data);
      const coursesData = response.data;

      // Populate formData with fetched data
      setFormData((prevState) => ({
        ...prevState,
        coursename: coursesData.data.coursename,
        courseimage: coursesData.data.courseimage,
        coursesize: coursesData.data.coursesize,
        duration: coursesData.data.duration,
        classtime: coursesData.data.classtime,
        fees: coursesData.data.fees,
        coursedescription: coursesData.data.coursedescription,
        imagename: coursesData.data.imagename,
      }));
      setImageUrl(coursesData.data.courseimage);
      console.log(formData);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  ///////updatebyId courses/////

  const updateCourse = async () => {
    try {
      const formDataForUpdate = new FormData();
      formDataForUpdate.append("coursename", formData.coursename);
      formDataForUpdate.append("coursesize", formData.coursesize);
      formDataForUpdate.append("duration", formData.duration);
      formDataForUpdate.append("classtime", formData.classtime);
      formDataForUpdate.append("fees", formData.fees);
      formDataForUpdate.append("coursedescription", formData.coursedescription);
      formDataForUpdate.append("courseimage", image);

      await axios.put(`${ROOT_URL}/idbyupdatecourse`, formDataForUpdate, {
        headers: {
          id: id,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Course updated successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error("Error updating course:", error);
      toast.error("Failed to update course. Please try again later.");
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);

      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImageUrl("");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container mt-5">
      <div className="border border-gray rounded p-4 mb-5">
        <h5 className="text-primary mb-4">Update Courses</h5>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Course Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Course Name"
            id="courseName"
            name="coursename"
            value={formData.coursename}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Course Size</label>
          <input
            type="text"
            className="form-control"
            placeholder="Course Size"
            id="courseSize"
            name="coursesize"
            value={formData.coursesize}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Duration</label>
          <input
            type="text"
            className="form-control"
            placeholder="Duration"
            id="Duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Class Time</label>
          <input
            type="text"
            className="form-control"
            placeholder="Class Time"
            id="ClassTime"
            name="classtime"
            value={formData.classtime}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Fee</label>
          <input
            type="text"
            className="form-control"
            placeholder="Fee"
            id="Fee"
            name="fees"
            value={formData.fees}
            onChange={handleChange}
          />
        </div>
        <div className="form-group container p-2" style={{ width: "100%" }}>
          <label htmlFor="packageNameInput">Courses Description</label>
          <input
            type="text"
            className="form-control"
            placeholder="Courses Description"
            id="coursedescription"
            name="coursedescription"
            value={formData.coursedescription}
            onChange={handleChange}
          />
        </div>
        <div
          className="border border-gray rounded p-4 mb-3"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginRight: "20px", flex: "0 0 auto" }}>
            <h6 className="text-primary mb-3">Add Image</h6>
            <div className="input-group mb-3">
              <label htmlFor="inputGroupFile02" className="form-control">
                Choose File
                <input
                  type="file"
                  className="d-none"
                  id="inputGroupFile02"
                  name="courseimage"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {imageUrl && (
            <div
              className="mt-3"
              style={{
                position: "relative",
                flex: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px dashed #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Uploaded"
                  style={{ maxWidth: "20%" }}
                />
                <button
                  onClick={handleCancelImage}
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="container">
          <div className="row justify-content-end ">
            <div className="col-auto mx-auto">
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={updateCourse}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Updatecourses;
